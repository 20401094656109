<template>
  <div class="page" style="text-align: left; padding: 23px 30px">
    <h3 style="padding-bottom:10px;">Välkommen till Delegationsportalen</h3>
    <div
        v-masonry-tile
        class="card"
        v-if="pendingUpdates.length > 0"
        style="border: 2px solid #fbe1e2 !important; width: calc(100% - 30px);"
      >
        <h5>
          <i class="bi bi-alarm" style="margin-right: 10px"></i>Pågående ändringar
        </h5>
        <div class="home-list">
          <div v-for="pendingUpdate in pendingUpdates" :key="pendingUpdate.id">
            Det finns en ändring för {{ pendingUpdate.title }}.
            <b-button
              class="btn btn-primary"
              @click="navigateToChanges"
              style="float: right; font-size: 13px !important"
              >Se ändring</b-button
            >
          </div>
        </div>
        <router-link :to="`/andringar/`" class="head-link"
          >Till pågående ändringar →</router-link
        >
      </div>
    <div
      v-masonry="containerId"
      transition-duration="0.3s"
      item-selector=".card"
      class="home"
    >
      <div v-masonry-tile class="card">
        <h5>
          <i class="bi bi-bookmark" style="margin-right: 10px"></i
          >Delegationsordningar
        </h5>
        <div v-if="linkTables" class="home-list">
          <router-link
            :to="`/delegationsordningar/${linkTable.id}`"
            v-for="linkTable in linkTables"
            :key="linkTable.id"
            >{{ linkTable.title }}</router-link
          >
        </div>
      </div>
      <div v-masonry-tile class="card">
        <h5><i class="bi bi-people" style="margin-right: 10px"></i>Delegater</h5>
        <div v-if="users" class="home-list">
          <div v-for="user in users" :key="user.key">{{ user.titel }}</div>
        </div>
        <router-link :to="`/delegater/`" class="head-link"
          >Redigera delegater →</router-link
        >
      </div>
      <div v-masonry-tile class="card" style="display:none;">
        <h5>
          <i class="bi bi-alarm" style="margin-right: 10px"></i>Senaste ändringar
        </h5>
        <table class="version-table table table-striped table-bordered">
          <tr>
            <th>Datum</th>
            <th>Ändring</th>
          </tr>
          <tr v-for="version in versions" :key="version.key">
            <td>{{ version.date }}</td>
            <td>Ändring av: {{ version.title }}</td>
          </tr>
        </table>
        <router-link :to="`/delegater/`" class="head-link"
          >Se fler ändringar →</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const Delegationsportalen = {
  apiKey: "AIzaSyCNQGyNShyUTbCWFPDFfxIdn9Q6b0SQMf8",
  authDomain: "delegationsportalen.firebaseapp.com",
  projectId: "delegationsportalen",
  storageBucket: "delegationsportalen.appspot.com",
  messagingSenderId: "537346964631",
  appId: "1:537346964631:web:1e864405c638f01bcd7dd2",
};

firebase.initializeApp(Delegationsportalen);

const db = firebase.firestore();

export default {
  name: "App",
  data() {
    return {
      linkTables: [],
      users: [],
      versions: [],
      pendingUpdates: [],
    };
  },
  mounted() {
    this.fetchUsers();
    this.fetchVersions();
    db.collection("Delegationsordning")
      .orderBy("title", "asc")
      .onSnapshot((snapshot) => {
        this.linkTables.splice(0); // clear the array
        this.tables = snapshot.docs.map((doc) => {
          const tableData = doc.data();
          tableData.id = doc.id; // add document ID to table data
          //console.log(tableData.id);
          this.linkTables.push(tableData);
        });
        this.loaded = true;
        console.log(this.linkTables);
      });
    db.collection("TempDelegationsordning").onSnapshot((snapshot) => {
      this.pendingUpdates.splice(0); // clear the array
      this.tables = snapshot.docs.map((doc) => {
        const tableData = doc.data();
        tableData.id = doc.id; // add document ID to table data
        //console.log(tableData.id);
        this.pendingUpdates.push(tableData);
      });
      this.loaded = true;
      console.log(this.pendingUpdates);
    });
  },
  methods: {
    navigateToChanges() {
      this.$router.push('/andringar/');
    },
    fetchUsers() {
      // Fetch all users from Firestore collection "Delegater"
      db.collection("Delegater")
        .get()
        .then((querySnapshot) => {
          const users = [];
          querySnapshot.forEach((doc) => {
            users.push(doc.data());
          });
          this.users = users;
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    },
    fetchVersions() {
      // Fetch all users from Firestore collection "Delegater"
      db.collection("Version")
        .get()
        .then((querySnapshot) => {
          const versions = [];
          querySnapshot.forEach((doc) => {
            versions.push(doc.data());
          });
          this.versions = versions;
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    },
  },
};
</script>

<style>
.home .card {
  width: calc(50% - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  float: left;
  min-height: 227px;
}

.home-list {
  background: #f6f6f6;
}

.home-list a,
.home-list div {
  width: 100%;
  float: left;
  padding: 5px;
  text-decoration: none;
  color: #00627d;
}

.home-list a:nth-child(even),
.home-list div:nth-child(even) {
  background: #e7f1f3;
}

h5 {
  margin-bottom: 15px;
}

.head-link {
  margin-top: 15px;
  float: right;
  width: auto;
  text-align: right;
  color: #00627d;
}

.version-table tr td {
  width: 65%;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.version-table tr td:first-child {
  width: 25%;
}
</style>
