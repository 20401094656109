<template>
  <div v-if="loaded" style="text-align: left; padding: 30px; position: relative">
    <div>
      <div class="card sidemenu" v-show="showSideMenu">
        <button class="btn btn-primary" @click="showSideMenu = !showSideMenu" style="margin-bottom:15px; width: 90px;">
          <i class="bi bi-x" style="margin-right: 10px"></i>Stäng
        </button>
        <h3>Innehållsförteckning</h3>
        <div v-for="newTable in updatedTables" :key="newTable.id" class="list-group">
          <h5>
            <a :href="'#' + generateId(newTable.title)">{{ newTable.title }}</a>
          </h5>
          <ul>
            <li v-for="(avsnitt, avsnittIndex) in newTable.rows" :key="avsnitt.id">
              <a v-if="avsnitt.avsnitt !== ''" :href="'#' + generateId(avsnitt.avsnitt)">{{ (avsnittIndex + 1) + ". " + avsnitt.avsnitt }}</a>
              <ul v-if="avsnitt.allTables">
                <li v-for="(table, tableIndex) in avsnitt.allTables" :key="table.key">
                  <a v-if="table.id" :href="'#' + generateId(table.id)">{{ (avsnittIndex + 1) + "." + (tableIndex + 1) + ". " + table.id }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <b-button @click="toggleEditorMode" v-if="isLoggedIn && isAdmin" class="btn btn-primary edit-button">
        {{ editorMode ? "Sluta redigera" : "Redigera" }}
      </b-button>
    </div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Delegationsförteckning" active>
          <div v-for="newTable in updatedTables" :key="newTable.id" :class="{'editingDelegation': hasEditingDelegationClass(newTable.id)}" style="padding:15px;">
            <div v-show="editorMode">
              <button @click="deleteDocument(newTable.id)" class="btn btn-danger"
                style="width: 222px; position: absolute; right: 40px">
                Radera delegationsordning
              </button>
              <button @click="copyToDelegationsordning(newTable.id)" class="btn btn-danger"
                style="width: 222px; position: absolute; right: 40px;">
                Synca
              </button>
            </div>
            <div v-show="editorMode">
              <b-form-input label="Titel:" v-model="newTitle" type="text" style="width: 50%; float: left" />
              <b-button @click="updateTitle(newTable.id, newTitle)" class="btn btn-primary"
                  style="margin-left: 10px">Spara titel</b-button>
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h3 :id="generateId(newTable.title)" tabindex="0" style="padding:0px; margin: 0px;" v-show="!editorMode">
              {{ newTable.title }}
              </h3>
              <div v-if="!editorMode">
                <button class="btn btn-primary" @click="showSideMenu = !showSideMenu" style="margin: 15px 15px 15px 0px;">
                  <i class="bi bi-list-columns" style="margin-right: 10px"></i>Innehållsförteckning
                </button>
                <b-button class="btn btn-primary" @click="handlePdf()" style="margin-right: 15px;">
                  <i class="bi bi-file-pdf-fill" style="margin-right: 10px"></i>PDF</b-button>
                <b-button class="btn btn-primary" @click="handlePdfNoVd()">
                  <i class="bi bi-file-pdf-fill" style="margin-right: 10px"></i>PDF utan vidaredelegering</b-button>
              </div>
            </div>
            <div v-for="(test, avsnittIndex) in newTable.rows" :key="test.key" :class="{
              'editingDelegationTable': hasEditingDelegationClass(test.id),
              'toolbar-wrapper': editorMode
            }"
              style="margin-top:30px;display:block;float:left;width:100%;">
              <h4 :id="generateId(test.avsnitt)" style="margin-bottom: 20px" v-if="test.avsnitt !== ''"
                v-show="!editorMode">
                {{ avsnittIndex + 1 }}. {{ test.avsnitt }}
              </h4>
              <b-form-group id="input-group-1" v-show="editorMode" label="Avsnitt:" label-for="input-1">
                <b-form-input v-model="newAvsnitt[avsnittIndex]" type="text" :placeholder="test.avsnitt"
                  style="width: 50%; float: left" />
                <b-button @click="updateAvsnitt(avsnittIndex, newAvsnitt, newTable.id)" class="btn btn-primary"
                  style="margin-left: 10px">Spara avsnitt</b-button>
                  <b-button @click="deleteAvsnitt(newTable.id, test.id, avsnittIndex)" class="btn btn-danger" v-show="editorMode" style="float:right;">
                      Radera avsnitt
                    </b-button>
              </b-form-group>
              <div v-if="editorMode" style="margin-top:30px;">
                <b-button v-b-toggle:my-collapse @click="setCurrentEditingIndexAvsnitt(avsnittIndex, test.fritext)">
                  Fritextsfält för avsnitt
                </b-button>
                <b-collapse id="my-collapse" v-if="currentEditingIndex === avsnittIndex">
                  <ckeditor class="fritext" :editor="editor" v-model="avsnittFritextValue" :config="editorConfig" @ready="onEditorReady">
                  </ckeditor>
                  <b-button @click="fritextAvsnitt(avsnittIndex, avsnittFritextValue, newTable.id)" class="btn btn-primary"
                  style="margin-top:10px; float:right;">Spara fritext för avsnitt</b-button>
                </b-collapse>
              </div>
              <div v-else v-html="test.fritext"></div>
              <div v-for="(row, tableIndex) in test.allTables" :key="row.id"  style="float: left;width: 100%;margin-top: 15px;" :class="{'editingDelegationTable': hasEditingDelegationClass(row.tableId)}">
                <div v-if="row.id.length > 0" style="display: flex; justify-content: space-between; padding-bottom: 10px;">
                  <span>
                    <h5 :id="generateId(row.id)" v-show="!editorMode" v-if="row.id">
                      {{ avsnittIndex + 1 + "." }}{{ tableIndex + 1 + ". " + row.id }}
                    </h5>
                  </span>
                  <div v-if="!editorMode">
                  <button :id="`popover-1-${tableIndex + 1 + row.id}`" variant="primary" style="float:right;background: none;border: 1px solid #03617d;color: #03617d;border-radius: 5px;" v-if="isAdmin || isLoggedIn"><i class="bi bi-three-dots-vertical"></i></button>
                    <b-popover
                      :target="`popover-1-${tableIndex + 1 + row.id}`"
                      :placement="placement"
                      title="Verktyg"
                      triggers="hover focus"
                      custom-class="custom-popover"
                    >
                    <b-button @click="openReport((avsnittIndex + 1) + '.' + (tableIndex + 1) + '. ' + row.id, (avsnittIndex + 1) + '. ' + test.avsnitt, newTable.title)" class="btn btn-info">Anmäl ändring</b-button>
                  </b-popover>
                  </div>
                </div>
                <b-modal :id="(avsnittIndex + 1) + '.' + (tableIndex + 1) + '. ' + row.id" size="xl" :ref="`addReport-${(avsnittIndex + 1) + '.' + (tableIndex + 1) + '. ' + row.id}-modal`" title="Anmäl ändring" hide-footer>
                  <b-form @submit.prevent="submitForm">
                      <label class="sr-only" for="title">Delegationsordning</label>
                      <b-form-select class="form-select" id="title" v-model="reported.title" disabled>
                          <option :value="newTable.title">{{newTable.title}}</option>
                      </b-form-select>
                      <br>
                      <label for="avsnitt">Avsnitt</label>
                      <b-form-input type="text" id="avsnitt" v-model="reported.avsnitt" disabled/>
                      <br>
                      <label class="sr-only" for="tabell">Tabell:</label>
                      <b-form-input type="text" id="tabell" v-model="reported.tabell" disabled/>
                      <br>
                      <label class="sr-only" for="message">Meddelande:</label>
                      <b-form-textarea type="text" id="message" v-model="reported.message" />
                      <br>
                      <b-button class="btn btn-primary" @click="closeReport((avsnittIndex + 1) + '.' + (tableIndex + 1) + '. ' + row.id)" type="submit">Anmäl ändring</b-button>
                  </b-form>
                  <div v-if="submitted">
                      <p>Din ändringsanmälan har registrerats</p>
                  </div>
                </b-modal>
                <b-form-group id="input-group-1" v-show="editorMode" style="margin: 20px 0px" label="Rubrik för tabell:"
                  label-for="input-2">
                  <b-form-input v-model="newRubrik[avsnittIndex + tableIndex]" type="text" :placeholder="row.id"
                    style="width: 50%; float: left" />
                  <b-button @click="updateRubrik(avsnittIndex, tableIndex, newRubrik, newTable.id)"
                    class="btn btn-primary" style="margin-left: 10px">Spara rubrik</b-button>
                    <b-button @click="deleteTable(row.id, newTable.id)" class="btn btn-danger" v-show="editorMode" style="float:right;">
                      Radera tabell
                    </b-button>
                </b-form-group>
                <div v-if="editorMode" style="margin-top:30px; margin-bottom:20px;">
                  <b-button v-b-toggle:my-collapse-tabell @click="setCurrentEditingIndexTabell(avsnittIndex, row.fritext)">
                    Fritextsfält för tabell
                  </b-button>
                  <b-collapse id="my-collapse-tabell" v-if="currentEditingIndex === avsnittIndex">
                    <ckeditor class="fritext" :editor="editor" v-model="tabellFritextValue" :config="editorConfig" @ready="onEditorReady">
                    </ckeditor>
                    <b-button @click="fritextTabell(avsnittIndex, tableIndex, tabellFritextValue, newTable.id)" class="btn btn-primary"
                    style="margin-top:10px; margin-bottom:20px; float:right;">Spara fritext för tabell</b-button>
                  </b-collapse>
              </div>
              <div v-else v-html="row.fritext"></div>
                <form @submit.prevent="saveEditBoth">
                  <table class="table table-striped table-bordered" tabindex="0">
                    <thead style="background: #00627d; color: white">
                      <tr>
                        <th style="width: 5%">Nr</th>
                        <th style="width: 20%">Ärendetyp</th>
                        <th style="width: 10%">Delegat</th>
                        <th style="width: 6%">Vidaredelegation</th>
                        <th style="width: 6%">Lagrum</th>
                        <th style="width: 19%">Anmärkning</th>
                        <th style="width: 14%">Ärenden som vidaredelegeras</th>
                        <th style="width: 10%">Vidaredelegat</th>
                        <th style="width: 10%" v-show="editorMode">Ändra</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(rowTable, rowIndex) in row.table" :key="rowTable.id" :ref="rowTable.id"
                        :class="{ changed: hasChanges(rowTable.id) && isAdmin, newChanged: hasNewChanges(rowTable.id) && isAdmin, pendingDelete: hasRemovedChanges(rowTable.id) && isAdmin,  editingDelegationRow: hasEditingDelegationClass(row.tableId) && isAdmin}">
                        <td tabindex="0">
                          {{ avsnittIndex + 1 }}.<span v-if="row.id">{{ tableIndex + 1 + "." }}</span>{{ rowIndex + 1 }}
                        </td>
                        <td tabindex="0" v-if="editingRow == null" :class="{ tdchange: hasChanges(rowTable.id) && hasTdChanges(rowTable.id, 'arendetyp') && isAdmin }">
                          {{ rowTable.arendetyp }}
                        </td>
                        <td v-else>
                          <label v-if="currentRowIndex == rowIndex + rowTable.id">
                            <textarea class="form-control" v-model="editArendetyp" />
                          </label>
                          <p v-else>{{ rowTable.arendetyp }}</p>
                        </td>
                        <td tabindex="0" v-if="editingRow == null" :class="{ tdchange: hasChanges(rowTable.id) && hasTdChanges(rowTable.id, 'delegat') && isAdmin}">
                          <span v-b-modal.modal-5 @click="matchDelegat(rowTable.delegat)"
                            style="text-decoration: underline">{{ rowTable.delegat }}</span>
                        </td>
                        <td v-else>
                          <select v-if="currentRowIndex == rowIndex + rowTable.id" class="custom-select form-control"
                            v-model="editDelegat" :value="rowTable.delegat">
                            <option v-for="user in users" :key="user.key" :value="user.titel">
                              {{ user.titel }}
                            </option>
                          </select>
                          <p v-else>{{ rowTable.delegat }}</p>
                        </td>
                        <td tabindex="0" v-if="editingRow == null" :class="{ tdchange: hasChanges(rowTable.id) && hasTdChanges(rowTable.id, 'vd') && isAdmin }">
                          {{ rowTable.vd }}
                        </td>
                        <td v-else>
                          <select v-if="currentRowIndex == rowIndex + rowTable.id" class="custom-select form-control"
                            v-model="editVd" :value="rowTable.vd">
                            <option>Nej</option>
                            <option>Ja</option>
                          </select>
                        </td>
                        <td tabindex="0" v-if="editingRow == null" :class="{ tdchange: hasChanges(rowTable.id) && hasTdChanges(rowTable.id, 'lagrum') && isAdmin }">
                          {{ rowTable.lagrum }}
                        </td>
                        <td v-else>
                          <label v-if="currentRowIndex == rowIndex + rowTable.id">
                            <textarea class="form-control" v-model="editLagrum" />
                          </label>
                          <p v-else>{{ rowTable.lagrum }}</p>
                        </td>
                        <td tabindex="0" v-if="editingRow == null" :class="{ tdchange: hasChanges(rowTable.id) && hasTdChanges(rowTable.id, 'anmarkning') && isAdmin }">
                          {{ rowTable.anmarkning }}
                        </td>
                        <td v-else>
                          <label v-if="currentRowIndex == rowIndex + rowTable.id">
                            <textarea class="form-control" v-model="editAnmarkning" />
                          </label>
                          <p v-else>{{ rowTable.anmarkning }}</p>
                        </td>
                        <td tabindex="0" v-if="editingRow == null" :class="{ tdchange: hasChanges(rowTable.id) && hasTdChanges(rowTable.id, 'arendenVidare') && isAdmin }">
                          <div v-for="(arenden, index) in rowTable.arendenVidare" :key="index" :class="`arendenArray arendenEntry-${rowTable.id}-${index}`">
                            <span>
                              {{ arenden }}
                            </span>
                          </div>
                        </td>
                        <td v-else>
                          <div v-for="(arenden, index) in rowTable.arendenVidare" :key="index">
                            <textarea v-if="currentRowIndex == rowIndex + rowTable.id" class="form-control" v-model="temporaryArendenVidare[index]" />
                          </div>
                          <b-button class="btn btn-secondary" @click.prevent="addEmptyEntryToArrays(
                                newTable.id,
                                avsnittIndex,
                                tableIndex,
                                rowIndex,
                              )">+</b-button>
                          <b-button class="btn btn-danger" @click.prevent="removeEntryFromArraysTest(
                                newTable.id,
                                avsnittIndex,
                                tableIndex,
                                rowIndex
                                )">-</b-button>
                        </td>
                        <td tabindex="0" v-if="editingRow == null" :class="{ tdchange: hasChanges(rowTable.id) && hasTdChanges(rowTable.id, 'vidaredelegat') && isAdmin }">
                          <div v-for="(delegat, index) in rowTable.vidaredelegat" :key="index" class="vidareArray" :class="`vidareArray vidareEntry-${rowTable.id}-${index}`">
                            <!-- Check if the current item is not 'Ingen vidaredelegat' or 'Nej' -->
                            <template v-if="delegat !== 'Ingen vidaredelegat' && delegat !== 'Nej'">
                                <span v-b-modal.modal-5 @click="matchDelegat(delegat)" style="text-decoration: underline">
                                  {{ delegat }}
                                </span>
                            </template>
                            
                            <!-- Handling for 'Ingen vidaredelegat' or 'Nej' -->
                            <span v-else style="opacity: 0.7">{{ delegat }}</span>
                          </div>
                        </td>
                        <td v-else>
                          <!-- Loop through vidaredelegat array to create multiple select inputs -->
                          <template v-if="currentRowIndex == rowIndex + rowTable.id">
                            <div v-for="(delegat, index) in rowTable.vidaredelegat" :key="index">
                              <select class="custom-select form-control"
                                      v-model="temporaryVidareDelegat[index]" 
                                      :value="delegat">
                                <option>Ingen vidaredelegat</option>
                                <option v-for="user in users" :key="user.key" :value="user.titel">
                                  {{ user.titel }}
                                </option>
                              </select>
                            </div>
                          </template>

                        </td>
                        <td v-show="editorMode">
                          <div :class="{ hidden: shouldHide }">
                            <b-button @click="
                              editRow(
                                newTable.id,
                                newTable.title,
                                avsnittIndex,
                                tableIndex,
                                rowIndex,
                                rowTable.id,
                                test.id,
                                row.tableId
                              )
                              " class="btn btn-secondary" style="margin: 0px 10px 0px 0px"><i
                                class="bi bi-pencil"></i></b-button>
                            <b-button @click="
                              deleteRow(
                                newTable.id,
                                avsnittIndex,
                                tableIndex,
                                rowIndex,
                                rowTable.id,
                                updatedTables[0].title
                              )
                              " class="btn btn-danger"><i class="bi bi-trash"></i></b-button>
                          </div>
                          <div :class="{ hidden: !shouldHide }" v-if="currentRowIndex == rowIndex + rowTable.id && editingRow">
                            <button type="submit" class="btn btn-primary">Spara</button>
                            <button style="margin-top:15px;" type="button" class="btn btn-secondary" @click="cancelEdit">
                              Avbryt
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr v-show="editorMode && toggleNewRow[generateId(row.id)]">
                        <td>
                        </td>
                        <td>
                          <label>
                            <textarea class="form-control" :class="currentTableIndex"
                              v-model="arendetyp[currentTableId + currentAvsnittIndex + currentAddNewRow]" />
                          </label>
                        </td>
                        <td>
                          <label>
                            <select class="custom-select form-control"
                              v-model="delegat[currentTableId + currentAvsnittIndex + currentAddNewRow]">
                              <option v-for="user in users" :key="user.key" :value="user.titel">
                                {{ user.titel }}
                              </option>
                            </select>
                          </label>
                        </td>
                        <td>
                          <label>
                            <select class="custom-select form-control"
                            v-model="vd[currentTableId + currentAvsnittIndex + generateId(row.id)]">
                              <option selected>Nej</option>
                              <option>Ja</option>
                            </select>
                          </label>
                        </td>
                        <td>
                          <label>
                            <textarea class="form-control" :class="currentTableIndex"
                              v-model="lagrum[currentTableId + currentAvsnittIndex + generateId(row.id)]" />
                          </label>
                        </td>
                        <td>
                          <label>
                            <textarea type="text" class="form-control" :class="currentTableIndex"
                              v-model="anmarkning[currentTableId + currentAvsnittIndex + generateId(row.id)]" />
                          </label>
                        </td>
                        <td>
                          <div v-for="(value, index) in arendenVidare[currentTableId + currentAvsnittIndex + generateId(row.id)]" :key="index">
                            <textarea class="form-control" v-model="arendenVidare[currentTableId + currentAvsnittIndex + generateId(row.id)][index]" />
                          </div>
                          <button class="btn btn-secondary" @click="addEmptyArendenVidare(currentTableId, currentAvsnittIndex, generateId(row.id)), addEmptyVidaredelegat(currentTableId, currentAvsnittIndex, generateId(row.id))">+</button>
                          <button class="btn btn-danger" @click="removeEmptyArendenVidare(currentTableId, currentAvsnittIndex, generateId(row.id)), removeEmptyVidaredelegat(currentTableId, currentAvsnittIndex, generateId(row.id))">-</button>
                        </td>
                        <td>
                          <!-- Loop through vidaredelegat array to create multiple select inputs -->
                          <div v-for="(delegat, index) in vidaredelegat[currentTableId + currentAvsnittIndex + generateId(row.id)]" :key="index">
                            <select class="custom-select form-control"
                                    v-model="vidaredelegat[currentTableId + currentAvsnittIndex + generateId(row.id)][index]">
                              <option>Ingen vidaredelegat</option>
                              <option v-for="user in users" :key="user.key" :value="user.titel">
                                {{ user.titel }}
                              </option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <!--<button @click="addNewRow(newTable.id, updatedTables[0].title, row.id)" class="btn btn-primary">
                            Lägg till
                          </button>-->
                          <button @click="addNewRowInDelegationsordning(newTable.id, test.id, updatedTables[0].title, row.tableId)" class="btn btn-primary">
                            Lägg till
                          </button>
                          <button type="button" style="margin-top:15px;" class="btn btn-secondary" @click="cancelEdit()">
                            Avbryt
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <div v-show="editorMode" class="toolbar-wrapper" style="display: flex; gap: 10px;">
                  <div>
                    <b-button @click="setCurrentAddRow(newTable.id, updatedTables[0].rows[avsnittIndex].allTables[tableIndex].table[0].id, avsnittIndex, tableIndex, generateId(row.id))" class="btn btn-primary"><i
                        class="bi bi-plus-square-dotted" style="margin-right: 10px;"></i>Lägg till rad<!-- <span style="font-weight: 600;">"{{ avsnittIndex + 1 + "." }}{{ tableIndex + 1 + ". " + row.id }}"</span>--></b-button>
                  </div>
                  <div v-show="editorMode">
                    <b-button v-b-modal.modal-2 @click="setCurrentTable(newTable.id, updatedTables[0].rows[avsnittIndex].id, avsnittIndex, tableIndex)"
                        class="btn btn-primary"><i class="bi bi-plus-square-dotted"
                          style="margin-right: 10px;"></i>Lägg till tabell<!--<span style="font-weight: 600;">"{{ avsnittIndex + 1 }}. {{ test.avsnitt }}"</span>--></b-button>
                  </div>
                </div>
              </div>
              <!--{{ updatedTables[0].rows[avsnittIndex] }}-->
              <div v-show="editorMode" style="float: left; margin-top: 20px;">
                  <b-button v-b-modal.modal-3 @click="setCurrentAvsnitt(newTable.id, avsnittIndex, tableIndex)"
                    style="margin-bottom: 15px;" class="btn btn-primary">
                    <i class="bi bi-plus" style="margin-right: 10px;"></i>Skapa nytt avsnitt
                  </b-button>
                </div>
            </div>
            <div>
            </div>
          </div>
          <b-modal id="modal-2" size="xl" ref="addTable-modal" title="Lägg till ny tabell" hide-footer
            style="background:#f8f9fa;">
            <form style="float:left; width:100%;" @submit.prevent="addNewTable(currentTableId, currentAvsnittIndex, currentTableIndex, updatedTables[0].title)">
              <b-form-group id="input-group-2" style="margin-bottom:15px;">
                <b-form-input v-model="title[currentAvsnittIndex + currentTableId]" type="text" placeholder="Rubrik" />
              </b-form-group>
              <table class="table table-striped table-bordered" tabindex="0" style="display:none;">
                <thead style="background: #3a7788; color: white">
                  <tr>
                    <th style="width: 5%">Nr</th>
                    <th style="width: 22%">Ärendetyp</th>
                    <th style="width: 10%">Delegat</th>
                    <th style="width: 10%">Vidaredelegation</th>
                    <th style="width: 10%">Lagrum</th>
                    <th style="width: 19%">Anmärkning</th>
                    <th style="width: 14%">Ärenden som vidaredelegeras</th>
                    <th style="width: 10%">Vidaredelegat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><label>
                        <textarea class="form-control" v-model="arendetyp[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td><label>
                        <select class="custom-select form-control"
                          v-model="delegat[currentAvsnittIndex + currentTableId]">
                          <option v-for="user in users" :key="user.key" :value="user.titel">
                            {{ user.titel }}
                          </option>
                        </select>
                      </label></td>
                    <td><label>
                      <select class="custom-select form-control" v-model="vd[currentAvsnittIndex + currentTableId]">
                        <option>Ja</option>
                        <option>Nej</option>
                      </select>
                      </label></td>
                    <td><label>
                        <textarea class="form-control" v-model="lagrum[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td><label>
                        <textarea class="form-control" v-model="anmarkning[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td><label>
                        <textarea class="form-control" v-model="arendenVidare[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td>
                      <label>
                        <select class="custom-select form-control" v-model="vidaredelegat[currentAvsnittIndex + currentTableId]">
                          <option>Ingen vidaredelegat</option>
                          <option v-for="user in users" :key="user.key" :value="user.titel">
                            {{ user.titel }}
                          </option>
                        </select>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="submit" class="btn btn-primary">Lägg till tabell</button>
            </form>
          </b-modal>
          <b-modal id="modal-3" size="xl" ref="addTable-modal" title="Lägg till nytt avsnitt" hide-footer
            style="background:#f8f9fa;">
            <form style="float:left; width:100%;" @submit.prevent="addNewAvsnitt(currentTableId, currentAvsnittIndex, updatedTables[0].title)">
              <b-form-group id="input-group-1" style="margin-bottom:15px;">
                <b-form-input v-model="avsnitt[currentAvsnittIndex + currentTableId]" type="text" placeholder="Avsnitt" />
              </b-form-group>
              <b-form-group id="input-group-2" style="margin-bottom:15px;">
                <b-form-input v-model="title[currentAvsnittIndex + currentTableId]" type="text" placeholder="Rubrik" />
              </b-form-group>
              <table class="table table-striped table-bordered" tabindex="0" style="display:none;">
                <thead style="background: #3a7788; color: white">
                  <tr>
                    <th style="width: 5%">Nr</th>
                    <th style="width: 22%">Ärendetyp</th>
                    <th style="width: 10%">Delegat</th>
                    <th style="width: 10%">Vidaredelegation</th>
                    <th style="width: 10%">Lagrum</th>
                    <th style="width: 19%">Anmärkning</th>
                    <th style="width: 14%">Ärenden som vidaredelegeras</th>
                    <th style="width: 10%">Vidaredelegat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><!--<label>
            <textarea
              class="form-control"
              v-model="nr[currentAvsnittIndex + currentTableId]"
            />
          </label>--></td>
                    <td><label>
                        <textarea class="form-control" v-model="arendetyp[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td><label>
                        <select class="custom-select form-control"
                          v-model="delegat[currentAvsnittIndex + currentTableId]">
                          <option v-for="user in users" :key="user.key" :value="user.titel">
                            {{ user.titel }}
                          </option>
                        </select>
                      </label></td>
                    <td>
                      <label>
                        <select class="custom-select form-control" v-model="vd[currentAvsnittIndex + currentTableId]">
                          <option>Ja</option>
                          <option>Nej</option>
                        </select>
                      </label></td>
                    <td><label>
                        <textarea class="form-control" v-model="lagrum[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td><label>
                        <textarea class="form-control" v-model="anmarkning[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td><label>
                        <textarea class="form-control" v-model="arendenVidare[currentAvsnittIndex + currentTableId]" />
                      </label></td>
                    <td>
                      <label>
                        <select class="custom-select form-control" v-model="vidaredelegat[currentAvsnittIndex + currentTableId]">
                          <option>Ingen vidaredelegat</option>
                          <option v-for="user in users" :key="user.key" :value="user.titel">
                            {{ user.titel }}
                          </option>
                        </select>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button type="submit" class="btn btn-primary">Lägg till avsnitt</button>
            </form>
          </b-modal>
          <b-modal id="modal-5" size="xl" :title="this.currentDelegat" ref="showDelegat-modal" @hidden="onHidden"
            hide-footer hide-close>
            <div v-if="this.matchDelegations != ''">
            <h6>{{ this.currentDelegat }} är delegat i följande ärenden:</h6>
            <table class="table table-striped table-bordered">
                <thead style="background: #ff8204; color: white;">
                    <tr>
                    <th>Delegationsordning</th>
                    <th>Avsnitt</th>
                    <th>Tabell</th>
                    <th>Nr</th>
                    <th>Typ av ärenden</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="matched in this.matchDelegations" :key="matched.key">
                        <td>{{ matched.delegationsordning }}</td>
                        <td @click="closeModal()"><router-link :to="'/delegationsordningar/' + matched.id">{{ matched.avsnitt }}</router-link></td>
                        <td>{{ matched.tabell }}</td>
                        <td>{{ matched.nr }}</td>
                        <td>{{ matched.arendetyp }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="this.matchVd != ''">
            <h6>{{ this.currentDelegat }} är vidaredelegat i följande ärenden:</h6>
            <table class="table table-striped table-bordered">
                <thead style="background: #ff8204; color: white;">
                    <tr>
                    <th>Delegationsordning</th>
                    <th>Avsnitt</th>
                    <th>Tabell</th>
                    <th>Nr</th>
                    <th>Typ av ärenden</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="matched in this.matchVd" :key="matched.key">
                        <td>{{ matched.delegationsordning }}</td>
                        <td><router-link :to="'/delegationsordningar/' + matched.id">{{ matched.avsnitt }}</router-link></td>
                        <td>{{ matched.tabell }}</td>
                        <td>{{ matched.nr }}</td>
                        <td>{{ matched.arendetyp }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="this.matchVd == '' && this.matchDelegations == ''">
          <h6>{{ this.currentDelegat }} får inte fatta delegationsbesut</h6>
        </div>
          </b-modal>
        </b-tab>
        <b-tab title="Om Delegationsordning">
          <div class="toolbar-wrapper" v-if="isLoggedIn && isAdmin">
            <b-button class="btn btn-cancel" @click="toggleEditorMode">{{ editorMode ? 'Avbryt' : 'Redigera' }}</b-button>
            <!--<b-button v-if="!editorMode" class="btn btn-primary" style="margin-left:15px;" @click="handleOmPdf()">
              <i class="bi bi-download" style="margin-right: 10px"></i>Exportera som PDF
            </b-button>-->
            <b-button v-if="editorMode" class="btn btn-primary" @click="saveHtml" style="margin-left:15px;">Spara
              ändringar</b-button>
          </div>
          <div class="ck-wrapper" v-if="editorMode">
            <ckeditor :editor="editor" v-model="html" :config="editorConfig" class="om-dele" @ready="onEditorReady">
            </ckeditor>
            <!--<vue-editor v-model="html"></vue-editor>-->
          </div>
          <div v-else style="text-align:left;" class="om-dele" v-html="html"></div>
        </b-tab>
        <b-tab title="Bilaga">
          <div class="toolbar-wrapper" v-if="isLoggedIn && isAdmin">
            <b-button class="btn btn-cancel" @click="toggleEditorMode">{{ editorMode ? 'Avbryt' : 'Redigera' }}</b-button>
            <!--<b-button v-if="!editorMode" class="btn btn-primary" style="margin-left:15px;" @click="handleOmPdf()">
              <i class="bi bi-download" style="margin-right: 10px"></i>Exportera som PDF
            </b-button>-->
            <b-button v-if="editorMode" class="btn btn-primary" @click="saveHtmlBilaga" style="margin-left:15px;">Spara
              ändringar</b-button>
          </div>
          <div class="ck-wrapper" v-if="editorMode">
            <ckeditor :editor="editor" v-model="bilagaHtml" :config="editorConfig" class="om-dele" @ready="onEditorReady">
          </ckeditor>
          </div>
          <div v-else>
            <div v-if="bilagaHtml" style="text-align:left;" class="om-dele" v-html="bilagaHtml"></div>
            <div v-else style="text-align:left;" class="om-dele">Delegationsordningen har ingen bilaga.</div>
          </div>
        </b-tab>
        <b-tab v-if="versions && isAdmin" title="Versionshantering">
          <div style="padding: 15px;">
            <h4>Versioner</h4>
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                    <th>Senaste ändring</th>
                    <th>Datum för ändring</th>
                    <th>Kommentar</th>
                    <th style="width: 200px; display:none;">Återställ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="version in versions" :key="version.id">
                        <td>{{ calculateTimeDifference(version.timestamp) }}</td>
                        <td>{{ formatDate(version.timestamp) }}</td>
                        <td>{{ version?.data?.comment }}</td>
                        <td style="display:none; justify-content: space-evenly">
                            <button class="btn btn-primary" @click="restoreVersion(version.originalId)">
                              Återställ
                            </button>
                            <span v-b-modal.modal-9 class="btn btn-primary" style="display: none;">
                              Se version
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!--<b-modal id="modal-9" size="xl" hide-footer>
              <h3>{{ versions[0]?.data.title }}</h3>
              <div v-for="data in versions[0]?.data.rows" :key="data.id">
                <h4>{{ data?.avsnitt }}</h4>
              <table class="table table-striped table-bordered" v-for="row in data?.allTables" :key="row.id">
                <thead>
                    <tr>
                      <th style="width: 5%">Nr</th>
                        <th style="width: 20%">Ärendetyp</th>
                        <th style="width: 10%">Delegat</th>
                        <th style="width: 6%">v.d.</th>
                        <th style="width: 6%">Lagrum</th>
                        <th style="width: 19%">Anmärkning</th>
                        <th style="width: 14%">Ärenden som vidaredelegeras</th>
                        <th style="width: 10%">Vidaredelegat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="table in row.table" :key="table.id">
                        <td>{{ table.nr }}</td>
                        <td>{{ table.arendetyp }}</td>
                        <td>{{ table.delegat }}</td>
                        <td>{{ table.vd }}</td>
                        <td>{{ table.lagrum }}</td>
                        <td>{{ table.anmarkning }}</td>
                        <td>{{ table.arendeVidare }}</td>
                        <td>{{ table.vidaredelegat }}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            </b-modal>-->
        </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <message ref="message" />
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// import draggable from 'vuedraggable';
import moment from "moment";
//import pdfMake from "pdfmake/build/pdfmake";
//import htmlToPdfmake from "html-to-pdfmake";
//import { VueEditor } from "vue2-editor";
import Message from "./PromptMessage.vue";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
//import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import '@ckeditor/ckeditor5-build-classic/build/translations/sv';
import { generateOmPdf, generatePdf, generatePdfNoVd } from './pdfgenerator.js';
import { db } from '../firebase.js';

export default {
  components: {
    //VueEditor,
    Message,
  },
  data() {
    return {
      editor: DecoupledEditor,
      editorConfig: {
        // todo confg
        language: 'sv',
      },
      reported: {
        title: "",
        avsnitt: "",
        tabell: "",
        message: ""
      },
      loaded: false,
      editorMode: false,
      isVisible: true,
      showSideMenu: false,
      toggleNewRow: [],
      delegationsordning: [],
      checkIndex: 1,
      version: "",
      html: "",
      bilagaHtml: "",
      currentDelegat: [],
      currentNr: "",
      changesList: [],
      tables: [],
      changes: [],
      updatedTables: [],
      tempDelegations: [],
      users: [],
      urlId: "",
      currentTableId: "",
      currentAvsnittIndex: "",
      currentTableIndex: "",
      currentAddNewRow: "",
      newDocTitle: "",
      newTitle: "",
      newAvsnitt: [],
      newRubrik: [],
      newRows: [],
      avsnitt: [],
      title: [],
      nr: [],
      arendetyp: [],
      delegat: [],
      vd: [],
      lagrum: [],
      anmarkning: [],
      arendenVidare: [],
      vidaredelegat: [],
      editingRow: null,
      currentRowIndex: null,
      editId: null,
      editNr: null,
      editArendetyp: null,
      editDelegat: null,
      editVd: null,
      editLagrum: null,
      editAnmarkning: null,
      editArendenVidare: null,
      editVidaredelegat: null,
      matchDelegations: [],
      matchVd: [],
      temporaryArendenVidare: [],
      temporaryVidareDelegat: [],
      placement: "",
      submitted: false,
      versions: [],
      isAdminStatus: false,
      currentTabellId: "",
      avsnittFritextValue: "",
      tabellFritextValue: "",
      currentEditingIndex: null
    };
  },
  metaInfo() {
    // Check if updatedTables has at least one element to avoid errors
    const hasUpdatedTables = this.updatedTables && this.updatedTables.length > 0;
    const defaultTitle = 'Värmdö kommun | Delegationsordning'; // Replace with your default title
    const defaultDescription = 'Delegationsordning gällande'; // Replace with your default description

    return {
      title: hasUpdatedTables ? 'Värmdö Kommun | ' + this.updatedTables[0].title : defaultTitle,
      meta: [
        {
          name: 'description',
          content: hasUpdatedTables ? this.updatedTables[0].title : defaultDescription,
        },
        // other meta tags...
      ],
    };
  },
  watch: {
    "$route.params.id"(newId) {
      // Do something when the id parameter changes
      this.urlId = newId;
      this.editorMode = false;
      this.editingRow = null;
      this.isVisible = true;
      this.toggleNewRow = [];
      this.temporaryArendenVidare = [];
      this.temporaryVidareDelegat = [];
      this.fetchVersions();
      this.reloadTable();
      this.loadHtml();
    },
  },
  mounted() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.loggedInUserName = user.email;

        // Fetch the ID token and update admin status
        try {
          const idTokenResult = await user.getIdTokenResult();
          this.isAdminStatus = idTokenResult.claims.isAdmin === true;
        } catch (error) {
          console.error("Error fetching user token:", error);
          this.isAdminStatus = false;
        }

      } else {
        this.loggedInUserName = null;
        this.isAdminStatus = false; // Reset admin status on logout
      }
    });
    // Fetch initial user list on component mount
    if (this.$store.state.delegations.length === 0) {
      // Om inte, hämta datan
      this.$store.dispatch('fetchDelegations');
    }
    this.fetchChanges();
    this.loadTempDelegations();
    this.urlId = this.$route.params.id;
    this.fetchUsers();
    this.reloadTable();
    this.loadHtml();
    this.fetchVersions();
    this.fetchAndUpdateData();
  },
  computed: {
    tempDelegationIds() {
      return Object.keys(this.tempDelegations);
    },
    hasEditingDelegationClass() {
      return (tableId) => {
        return this.isAdmin && this.tempDelegationIds.includes(tableId);
      };
    },
    shouldHide() {
      return !this.isVisible;
    },
    checkRowId(rowIndex, rowTable) {
      return this.currentRowIndex == rowIndex + rowTable;
    },
    generateId() {
      return (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, "-");
      };
    },
    isLoggedIn() {
      return firebase.auth().currentUser !== null; // Check if currentUser is not null
    },
    isAdmin() {
      // Now depends on the data property 'isAdminStatus'
      return this.isAdminStatus;
    },
    isRowInTempDelegations() {
        return (rowId) => {
            return Object.values(this.tempDelegations).some(entry => entry.id === rowId);
        };
    }
  },
  methods: {
    handleOmPdf() {
      generateOmPdf(this.html, this.updatedTables);
    },
    handlePdf() {
      generatePdf(this.html, this.updatedTables, this.bilagaHtml);
      this.reloadTable();
    },
    handlePdfNoVd() {
      generatePdfNoVd(this.html, this.updatedTables, this.bilagaHtml);
      this.reloadTable();
    },
    formatDate(timestamp) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      return new Intl.DateTimeFormat('sv-SE', options).format(new Date(timestamp));
    },
    closeModal() {
      this.$refs["showDelegat-modal"].hide();
    },
    addEmptyEntry() {
      this.temporaryArendenVidare.push("");
      this.temporaryVidareDelegat.push("");
    },
    addEmptyArendenVidare(tableId, avsnittIndex, rowIndex) {
      const key = tableId + avsnittIndex + rowIndex;
      if (!this.arendenVidare[key]) {
        this.$set(this.arendenVidare, key, []);
      }
      this.arendenVidare[key].push('');
    },
    addEmptyVidaredelegat(tableId, avsnittIndex, rowIndex) {
      const key = tableId + avsnittIndex + rowIndex;
      if (!this.vidaredelegat[key]) {
        this.$set(this.vidaredelegat, key, []);
      }
      this.vidaredelegat[key].push('');
    },
    removeEmptyArendenVidare(tableId, avsnittIndex, rowIndex) {
      const key = tableId + avsnittIndex + rowIndex;
      if (!this.arendenVidare[key]) {
        this.$set(this.arendenVidare, key, []);
      }
      this.arendenVidare[key].pop();
    },
    removeEmptyVidaredelegat(tableId, avsnittIndex, rowIndex) {
      const key = tableId + avsnittIndex + rowIndex;
      if (!this.vidaredelegat[key]) {
        this.$set(this.vidaredelegat, key, []);
      }
      this.vidaredelegat[key].pop();
    },
    calculateTimeDifference(timestamp) {
      const currentTime = new Date();
      const diff = currentTime - timestamp; // Difference in milliseconds

      if (diff < 60000) {
        return Math.floor(diff / 1000) + " sek";
      } else if (diff < 3600000) {
        return Math.floor(diff / 60000) + " min";
      } else if (diff < 86400000) {
        return Math.floor(diff / 3600000) + " tim";
      } else {
        return Math.floor(diff / 86400000) + " dagar";
      }
    },
    async fetchVersions() {
      const versionsRef = db.collection("VersionDelegationsordning")
        .doc(this.urlId)
        .collection("Versions");

      const snapshot = await versionsRef.get();

      this.versions = snapshot.docs.map((doc) => {
        const originalId = doc.id;
        const timestamp = new Date(originalId);
        const formattedTimestamp = timestamp.toISOString().slice(0, 16).replace("T", " ");

        return {
          originalId: originalId, // Store original ID
          formattedId: formattedTimestamp, // Store formatted ID for display purposes
          data: doc.data(),
          timestamp: timestamp
        };
      });
      this.versions.reverse();
    },
    async restoreVersion(versionId) {
      console.log("Attempting to restore version:", versionId);
      try {
        const versionRef = db.collection("VersionDelegationsordning")
          .doc(this.urlId)
          .collection("Versions")
          .doc(versionId);

        const versionSnapshot = await versionRef.get();

        if (versionSnapshot.exists) {
          const versionData = versionSnapshot.data();

          const mainDocRef = db.collection("PendingDelegationsordning").doc(this.urlId);
          await mainDocRef.set(versionData, { merge: true });

          this.fetchVersions(this.urlId);
          this.reloadTable();
          this.loadHtml();
          this.fetchAndUpdateData();

          console.log("Version återställd framgångsrikt!");
        } else {
          console.log("Versionen hittades inte.");
        }
      } catch (error) {
        console.error("Error in restoring version:", error);
      }
    },
    async copyVersion(versionId) {
      console.log("Attempting to restore version:", versionId);
      try {
        const versionRef = db.collection("VersionDelegationsordning")
          .doc(this.urlId)
          .collection("Versions")
          .doc(versionId);

        const versionSnapshot = await versionRef.get();

        if (versionSnapshot.exists) {
          const versionData = versionSnapshot.data();

          const mainDocRef = db.collection("TempDelegationsordning").doc(this.urlId);
          await mainDocRef.set(versionData, { merge: true });

          this.fetchVersions(this.urlId);
          this.reloadTable();
          this.loadHtml();
          this.fetchAndUpdateData();

          console.log("Version återställd framgångsrikt!");
        } else {
          console.log("Versionen hittades inte.");
        }
      } catch (error) {
        console.error("Error in restoring version:", error);
      }
    },
    async fetchAndUpdateData() {
      const snapshot = await db.collection('PendingDelegationsordning').get();
      snapshot.forEach((doc) => {
        let data = doc.data();
        data.rows.forEach((row, rowIndex) => {
          row.allTables.forEach((allTable, allTableIndex) => {
            allTable.table.forEach((table, tableIndex) => {
              // Update the 'nr' field with the correct index.
              let newNr = `${rowIndex + 1}.${allTableIndex + 1}.${tableIndex + 1}`;
              this.$set(table, 'nr', newNr);
              // Update matching entries in 'TempDelegationsordning'
              this.updateTempDelegationsordning(table.id, newNr);
            });
          });
        });
        // Update 'delegationsordning' array
        this.delegationsordning.push(data);
        // Save the changes back to Firebase.
        this.saveChangesToFirebase(doc.id, data);
      });
      this.calculateHeight();
    },
    async updateTempDelegationsordning(id, newNr) {
      const snapshot = await db.collection('TempDelegationsordning').where('id', '==', id).get();
      snapshot.forEach((doc) => {
        let data = doc.data();
        data.rows[0].allTables[0].table[0].nr = newNr;
        this.saveChangesToFirebase(doc.id, data, 'TempDelegationsordning');
      });
    },
    saveChangesToFirebase(id, data, collectionName = 'PendingDelegationsordning') {
      db.collection(collectionName).doc(id).set(data)
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    },
    onEditorReady(editor) {
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    },
    loadTempDelegations() {
        db.collection("TempDelegationsordning").get()
            .then(snapshot => {
                let tempData = {};
                snapshot.forEach(doc => {
                    tempData[doc.id] = doc.data();
                });
                this.tempDelegations = tempData; // Assigning the data to tempDelegations
            })
            .catch(error => {
                console.error("Error retrieving documents: ", error);
            });
    },
    showMessage(message) {
      this.$refs.message.message = message;
      this.$refs.message.isVisible = true;
      setTimeout(() => {
        this.$refs.message.isVisible = false;
      }, 3000);
    },
    loadHtml() {
      const docRef = db.collection("PendingDelegationsordning").doc(this.urlId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          this.html = doc.data().about;
          this.bilagaHtml = doc.data().bilaga;
        }
      });
    },
    saveHtml() {
      const docRef = db.collection("PendingDelegationsordning").doc(this.urlId);
      docRef.update({
        about: this.html,
      })
        .then(() => {
          console.log("HTML saved successfully");
          this.showMessage("Dina ändringar har sparats!");
          this.editorMode = !this.editorMode;
        })
        .catch((error) => {
          console.error("Error saving HTML:", error);
        });
    },
    saveHtmlBilaga() {
      const docRef = db.collection("PendingDelegationsordning").doc(this.urlId);
      docRef.update({
        bilaga: this.bilagaHtml,
      })
        .then(() => {
          console.log("HTML saved successfully");
          this.showMessage("Dina ändringar har sparats!");
          this.editorMode = !this.editorMode;
        })
        .catch((error) => {
          console.error("Error saving HTML:", error);
        });
    },
    calculateHeight() {
      this.$nextTick(() => {
        for (let updatedTable of this.updatedTables) {
          for (let row of updatedTable.rows) {
            for (let allTable of row.allTables) {
              for (let table of allTable.table) {
                for (let index = 0; index < table.arendenVidare.length; index++) {
                  // Use unique identifiers to select the elements.
                  // It's up to you what you want to use, but the goal is to be as specific as possible.
                  const uniqueId = `${table.id}-${index}`;
                  const arendenEl = document.querySelector(`.arendenEntry-${uniqueId}`);
                  const vidareEl = document.querySelector(`.vidareEntry-${uniqueId}`);

                  if (arendenEl && vidareEl) {
                    const arendenHeight = arendenEl.clientHeight;
                    vidareEl.style.height = `${arendenHeight}px`;
                  }
                }
              }
            }
          }
        }
      });
    },
    reloadTable() {
      if (!this.isLoggedIn) {
        db.collection("Delegationsordning")
          .doc(this.urlId)
          .onSnapshot((doc) => {
            this.updatedTables.splice(0); // clear the array
            const tableData = doc.data();
            tableData.id = doc.id; // add document ID to table data
            this.updatedTables.push(tableData);
            this.loaded = true;
          });
      } else {
        db.collection("PendingDelegationsordning")
          .doc(this.urlId)
          .onSnapshot((doc) => {
            this.updatedTables.splice(0); // clear the array
            const tableData = doc.data();
            tableData.id = doc.id; // add document ID to table data
            this.updatedTables.push(tableData);
            this.loaded = true;
          });
      }
    },
    toggleEditorMode() {
      this.editorMode = !this.editorMode;
      if (!this.editorMode) {
        this.cancelEdit();
      }
    },
    addVersion(update) {
      console.log("New version added");
      db.collection("Version")
        .add({
          id: Math.random().toString(36).substr(2, 9),
          title: update,
          rows: [],
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          date: moment().format("ll"),
        })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    addNewDoc() {
      console.log("addDoc function called");
      db.collection("Delegationsordning")
        .add({
          id: Math.random().toString(36).substr(2, 9),
          title: this.newDocTitle,
          rows: [],
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.newDocTitle = "";
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    setCurrentTable(tableId, tabellId, avsnittIndex, tableIndex) {
      this.$set(this.toggleNewRow, tableId, !this.toggleNewRow[tableId]);
      this.currentTableId = tableId;
      this.currentTabellId = tabellId;
      this.currentAvsnittIndex = avsnittIndex;
      this.currentTableIndex = tableIndex;
    },
    setCurrentAvsnitt(tableId, avsnittIndex, tableIndex) {
      this.$set(this.toggleNewRow, tableId, !this.toggleNewRow[tableId]);
      this.currentTableId = tableId;
      this.currentAvsnittIndex = avsnittIndex;
      this.currentTableIndex = tableIndex;
    },
    setCurrentAddRow(tableId, tabellId, avsnittIndex, tableIndex, rowId) {
      this.$set(this.toggleNewRow, rowId, !this.toggleNewRow[rowId]);
      this.currentTabellId = tabellId;
      this.currentTableId = tableId;
      this.currentAvsnittIndex = avsnittIndex;
      this.currentTableIndex = tableIndex;
      this.currentAddNewRow = rowId;
    },
    addNewTable(tableId, index, tableIndex, tableTitle) {
      const foundEntry = this.updatedTables.find(entry => entry.id === tableId);
      if (!foundEntry) {
        console.error("No entry found with id: ", tableId);
        return;
      }

      const newTableId = Math.random().toString(36).substr(2, 9);
      const tabellId = Math.random().toString(36).substr(2, 9);

      const newTable = {
        tableId: tabellId,
        id: this.title[index + tableId] ? this.title[index + tableId] : "",
        table: [
          {
            id: newTableId,
            nr: this.nr[index + tableId] ? this.nr[index + tableId] : "",
            arendetyp: this.arendetyp[index + tableId]
              ? this.arendetyp[index + tableId]
              : "",
            delegat: this.delegat[index + tableId]
              ? this.delegat[index + tableId]
              : "",
            vd: this.vd[index + tableId] ? this.vd[index + tableId] : "",
            lagrum: this.lagrum[index + tableId]
              ? this.lagrum[index + tableId]
              : "",
            anmarkning: this.anmarkning[index + tableId]
              ? this.anmarkning[index + tableId]
              : "",
            arendenVidare: this.arendenVidare[index + tableId]
              ? this.arendenVidare[index + tableId]
              : "",
            vidaredelegat: this.vidaredelegat[index + tableId]
              ? this.vidaredelegat[index + tableId]
              : "",
          }
        ]
      };

      if (typeof tableIndex === 'number' && tableIndex >= 0 && tableIndex <= foundEntry.rows[index].allTables.length) {
        foundEntry.rows[index].allTables.splice(tableIndex + 1, 0, newTable);
      } else {
        foundEntry.rows[index].allTables.push(newTable);
      }

      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      docRef.get().then(doc => {
        if (doc.exists) {
          const rows = doc.data().rows;
          rows[index].allTables = foundEntry.rows[index].allTables;

          docRef.update({ rows }).then(() => {
            // Handle successful update

          }).catch(error => {
            console.error("Error updating document: ", error);
          });
        } else {
          console.log("Document not found");
        }
      });

      const deleDocRef = db.collection("TempDelegationsordning").doc(tableId);
      deleDocRef.get().then(doc => {
        if (doc.exists) {
          const rows = doc.data().rows;
          rows[index].allTables = foundEntry.rows[index].allTables;

          deleDocRef.update({ rows }).then(() => {
            // Handle successful update
            this.$refs["addTable-modal"].hide();
            this.allTables = [];
            this.newTables = [];
            this.loadTempDelegations();
            this.fetchChanges();
            this.reloadTable();
          }).catch(error => {
            console.error("Error updating document: ", error);
          });
        } else {
          const tempDocRef = db.collection("TempDelegationsordning").doc(this.currentTabellId);
      tempDocRef.get().then(doc => {
        if (doc.exists) {
          let tempEntry = doc.data();
          // Determine correct index in allTables to add newTable
          // For simplicity, adding at the end
          tempEntry.rows[0].allTables.push(newTable);

          tempDocRef.update(tempEntry).then(() => {
            console.log("Row added to existing avsnitt/table in TempDelegationsordning");
            this.$refs["addTable-modal"].hide();
                      this.newTable = [];
                      this.newTables = [];
                      this.newRows = [];
                      this.title = [];
                      this.avsnitt = [];
                      this.delegat = [];
                      this.nr = [];
                      this.arendetyp = [];
                      this.vd = [];
                      this.lagrum = [];
                      this.anmarkning = [];
                      this.arendenVidare = [];
                      this.vidaredelegat = [];
                      this.tables = [];
                      this.toggleNewRow = [];
                      this.loadTempDelegations();
                      this.fetchChanges();
                      this.reloadTable();
          }).catch(error => {
            console.error("Error updating document in TempDelegationsordning: ", error);
          });
        } else {
          const tempDocRef = db.collection("TempDelegationsordning").doc(tabellId);
            tempDocRef.set({
              parentId: tableId,
              title: tableTitle, 
              avsnittIndex: index,
              type: "new-table",
              rows: [{
                allTables: [newTable],
              }],
            }).then(() => {
            console.log("Temporary document created successfully!");
                      this.$refs["addTable-modal"].hide();
                      this.newTable = [];
                      this.newTables = [];
                      this.newRows = [];
                      this.title = [];
                      this.avsnitt = [];
                      this.delegat = [];
                      this.nr = [];
                      this.arendetyp = [];
                      this.vd = [];
                      this.lagrum = [];
                      this.anmarkning = [];
                      this.arendenVidare = [];
                      this.vidaredelegat = [];
                      this.tables = [];
                      this.toggleNewRow = [];
                      this.loadTempDelegations();
                      this.fetchChanges();
                      this.reloadTable();
                    }).catch(error => {
            console.error("Error creating temporary document: ", error);
          });
        }
      }).catch(error => {
        console.error("Error fetching document: ", error);
      });
        }
      });
    },
    addNewAvsnitt(tableId, index, tableTitle) {
      // Find the entry in the updated tables
      const foundEntry = this.updatedTables.find((entry) => {
        return entry.id === tableId;
      });

      // Check if the entry was found
      if (!foundEntry) {
        console.error("No entry found with id: ", tableId);
        return;
      }

      // Create a new ID for the temporary post
      const newAvsnittId = Math.random().toString(36).substr(2, 9);
      const randomId = Math.random().toString(36).substr(2, 9);

      // Construct the new row
      const newRow = {
        id: newAvsnittId,
        avsnitt: this.avsnitt[index + tableId]
          ? this.avsnitt[index + tableId]
          : "",
        allTables: [{
          id: this.title[index + tableId] ? this.title[index + tableId] : "",
          table: [
            {
              id: randomId,
              nr: this.nr[index + tableId] ? this.nr[index + tableId] : "",
              arendetyp: this.arendetyp[index + tableId]
                ? this.arendetyp[index + tableId]
                : "",
              delegat: this.delegat[index + tableId]
                ? this.delegat[index + tableId]
                : "",
              vd: this.vd[index + tableId] ? this.vd[index + tableId] : "",
              lagrum: this.lagrum[index + tableId]
                ? this.lagrum[index + tableId]
                : "",
              anmarkning: this.anmarkning[index + tableId]
                ? this.anmarkning[index + tableId]
                : "",
              arendenVidare: this.arendenVidare[index + tableId]
                ? this.arendenVidare[index + tableId]
                : "",
              vidaredelegat: this.vidaredelegat[index + tableId]
                ? this.vidaredelegat[index + tableId]
                : "",
            }
          ]
        }]
      };

      // Fetch the existing 'PendingDelegationsordning' document
      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          let parentEntry = doc.data();

          // Initialize 'rows' if it doesn't exist or is empty
          if (!parentEntry.rows) {
            parentEntry.rows = [];
          }

          // Check if the index is within bounds and insert the new row
          if (index <= parentEntry.rows.length) {
            parentEntry.rows.splice(index + 1, 0, newRow);
          } else {
            console.log("Avsnitt index out of bounds");
            return;
          }

          // Update the 'PendingDelegationsordning' document
          docRef.update({ rows: parentEntry.rows }).then(() => {
            console.log("Avsnitt inserted successfully in PendingDelegationsordning");

            const deleDocRef = db.collection("TempDelegationsordning").doc(tableId);
            deleDocRef.get().then((doc) => {
                if (doc.exists) {
                  let parentEntry = doc.data();
                    // Initialize 'rows' if it doesn't exist or is empty
                    if (!parentEntry.rows) {
                      parentEntry.rows = [];
                    }

                    // Check if the index is within bounds and insert the new row
                    if (index <= parentEntry.rows.length) {
                      parentEntry.rows.splice(index + 1, 0, newRow);
                    } else {
                      console.log("Avsnitt index out of bounds");
                      return;
                    }

                    // Update the 'PendingDelegationsordning' document
                    deleDocRef.update({ rows: parentEntry.rows })
                    this.$refs["addTable-modal"].hide();
                } else {
                              // Create a temporary document in 'TempDelegationsordning'
            const tempDocRef = db.collection("TempDelegationsordning").doc(newAvsnittId);
            tempDocRef.set({
              id: newAvsnittId,
              parentId: tableId,
              title: tableTitle,
              avsnittIndex: index,
              type: "new-avsnitt",
              rows: [newRow],
            })
            .then(() => {
              console.log("Temporary document created successfully!");
              this.$refs["addTable-modal"].hide();
              // Resetting component state as needed
              this.newTables = [];
              this.newRows = [];
              this.newRow = [];
              this.title = [];
              this.avsnitt = [];
              this.delegat = [];
              this.nr = [];
              this.arendetyp = [];
              this.vd = [];
              this.lagrum = [];
              this.anmarkning = [];
              this.arendenVidare = [];
              this.vidaredelegat = [];
              this.tables = [];
              this.toggleNewRow = [];
              this.loadTempDelegations();
              this.fetchChanges();
              this.reloadTable();
            })
            .catch((error) => {
              console.error("Error creating temporary document: ", error);
            });
                }
              })
              .catch((error) => {
                console.error("Error fetching PendingDelegationsordning document:", error);
              });
          })
          .catch((error) => {
            console.error("Error updating PendingDelegationsordning document: ", error);
          });
        } else {
          console.log("Document not found in PendingDelegationsordning");
        }
      })
      .catch((error) => {
        console.error("Error fetching PendingDelegationsordning document:", error);
      });
    },
    addNewRowInDelegationsordning(delegationsId, avsnittId, title, tabellId) { //Delegationsordning
      this.toggleNewRow = [];
      const foundEntry = this.updatedTables.find((entry) => {
        return entry.id === this.currentTableId;
      });

      // Copy existing rows into newRows
      const currentTableGroup = foundEntry.rows[this.currentAvsnittIndex].allTables[this.currentTableIndex];
      if (currentTableGroup.table.length > 0) {
        for (let i = 0; i < currentTableGroup.table.length; i++) {
          this.newRows.push(currentTableGroup.table[i]);
        }
      }

      const rowId = Math.random().toString(36).substr(2, 9)

      const newRow = {
        // Add the details for the new row here
        id: rowId, // Example row ID
        // ... other row properties
        nr: this.nr[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.nr[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        delegat: this.delegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.delegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        arendetyp: this.arendetyp[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.arendetyp[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        vd: this.vd[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.vd[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        lagrum: this.lagrum[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.lagrum[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        anmarkning: this.anmarkning[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.anmarkning[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        arendenVidare: Array.isArray(this.arendenVidare[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow])
          ? [...this.arendenVidare[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]]
          : [],
        vidaredelegat: Array.isArray(this.vidaredelegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow])
          ? [...this.vidaredelegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]]
          : [],
      };
      // Function to update a collection with the new row
      const updateDelegation = (collectionName) => {
        const docRef = db.collection(collectionName).doc(delegationsId);
        docRef.get().then((doc) => {
          if (doc.exists) {
            const rows = doc.data().rows;
            // Assuming rows is an array and you're adding the new row to the first element's table
            rows[this.currentAvsnittIndex].allTables[this.currentTableIndex].table.push(newRow); // Adjust the index based on where you want to add the row
            docRef.update({ rows });
          } else {
            console.log(`${collectionName} document not found`);
          }
        }).catch((error) => {
          console.error(`Error updating ${collectionName}:`, error);
        });
      };

      const updateDelegationAvsnitt = (collectionName) => {
        const docRef = db.collection(collectionName).doc(avsnittId);
        docRef.get().then((doc) => {
          if (doc.exists) {
            let tempEntry = doc.data();
            tempEntry.rows[0].allTables[this.currentTableIndex].table.push(newRow);

            docRef.update(tempEntry)
              .then(() => {
                console.log("Row added to existing avsnitt/table in TempDelegationsordning");
                this.fetchChanges();
                this.loadTempDelegations();
                this.reloadTable();
                this.calculateHeight();
            })
              .catch((error) => {
                console.error("Error updating document in TempDelegationsordning: ", error);
            });
          } else {
            console.log(`${collectionName} document not found`);
          }
        }).catch((error) => {
          console.error(`Error updating ${collectionName}:`, error);
        });
      };

      const updateDelegationAvsnittTable = (collectionName) => {
        const docRef = db.collection(collectionName).doc(tabellId);
        docRef.get().then((doc) => {
          if (doc.exists) {
            let tempEntry = doc.data();
            tempEntry.rows[0].allTables[0].table.push(newRow);

            docRef.update(tempEntry)
              .then(() => {
                console.log("Row added to existing avsnitt/table in TempDelegationsordning");
                this.fetchChanges();
                this.loadTempDelegations();
                this.reloadTable();
                this.calculateHeight();
            })
              .catch((error) => {
                console.error("Error updating document in TempDelegationsordning: ", error);
            });
          } else {
            console.log(`${collectionName} document not found`);
          }
        }).catch((error) => {
          console.error(`Error updating ${collectionName}:`, error);
        });
      };

      const addRowInTemp = (collectionName) => {
        const tempDocRef = db.collection(collectionName).doc(rowId);
        tempDocRef.set({
          id: rowId,
          parentId: delegationsId,
          avsnittIndex: this.currentAvsnittIndex,
          tableIndex: this.currentTableIndex,
          title: title,
          type: "new",
          rows: [{
            allTables: [{
              id: avsnittId,
              table: [newRow],
            }],
          }],
        })
        .then(() => {
          console.log("New avsnitt/table created in TempDelegationsordning");
          this.newRows = [];
          this.toggleNewRow = [];
          this.fetchChanges();
          this.loadTempDelegations();
          this.reloadTable();
          this.calculateHeight();
        })
        .catch((error) => {
          console.error("Error creating new document in TempDelegationsordning: ", error);
        });
      };

      const deleDocRef = db.collection("TempDelegationsordning").doc(delegationsId);
        deleDocRef.get().then((doc) => {
          if (doc.exists) {
            // Update both collections
            updateDelegation("PendingDelegationsordning");
            updateDelegation("TempDelegationsordning");
            this.newRows = [];
            this.toggleNewRow = [];
          } else {
            const deleDocRef = db.collection("TempDelegationsordning").doc(avsnittId);
              deleDocRef.get().then((doc) => {
                if (doc.exists) {
                  // Update both collections
                  updateDelegation("PendingDelegationsordning");
                  updateDelegationAvsnitt("TempDelegationsordning");
                  this.newRows = [];
                  this.toggleNewRow = [];
                } else {
                  const deleDocRef = db.collection("TempDelegationsordning").doc(tabellId);
                    deleDocRef.get().then((doc) => {
                      if (doc.exists) {
                        // Update both collections
                        updateDelegation("PendingDelegationsordning");
                        updateDelegationAvsnittTable("TempDelegationsordning");
                        this.newRows = [];
                        this.toggleNewRow = [];
                      } else {
                        // If the document does not exist in TempDelegationsordning, just update PendingDelegationsordning
                        updateDelegation("PendingDelegationsordning");
                        addRowInTemp("TempDelegationsordning");
                        this.newRows = [];
                        this.toggleNewRow = [];
                      }
                    }).catch((error) => {
                      console.error(`Error:`, error);
                    });
                }
              }).catch((error) => {
                console.error(`Error:`, error);
              });
          }
        }).catch((error) => {
          console.error(`Error:`, error);
        });

    },
    addNewRow(id, title, allTableId) {
      this.toggleNewRow = [];
      const foundEntry = this.updatedTables.find((entry) => {
        return entry.id === this.currentTableId;
      });

      const rowId = Math.random().toString(36).substr(2, 9);

      const newRow = {
        id: rowId,
        nr: this.nr[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.nr[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        delegat: this.delegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.delegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        arendetyp: this.arendetyp[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.arendetyp[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        vd: this.vd[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.vd[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        lagrum: this.lagrum[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.lagrum[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        anmarkning: this.anmarkning[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          ? this.anmarkning[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]
          : "",
        arendenVidare: Array.isArray(this.arendenVidare[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow])
          ? [...this.arendenVidare[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]]
          : [],
        vidaredelegat: Array.isArray(this.vidaredelegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow])
          ? [...this.vidaredelegat[this.currentTableId + this.currentAvsnittIndex + this.currentAddNewRow]]
          : [],
      };

      // Copy existing rows into newRows
      const currentTableGroup = foundEntry.rows[this.currentAvsnittIndex].allTables[this.currentTableIndex];
      if (currentTableGroup.table.length > 0) {
        for (let i = 0; i < currentTableGroup.table.length; i++) {
          this.newRows.push(currentTableGroup.table[i]);
        }
      }

      const docRef = db
        .collection("PendingDelegationsordning")
        .doc(id);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          rows[this.currentAvsnittIndex].allTables[this.currentTableIndex].table = this.newRows;
          docRef
            .update({ rows })
        } else {
          console.log("Document not found");
        }
      });

      const deleDocRef = db
        .collection("TempDelegationsordning")
        .doc(id);
      deleDocRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          rows[this.currentAvsnittIndex].allTables[this.currentTableIndex].table = this.newRows;
          deleDocRef
            .update({ rows })
            .then(() => {
              // Clear newRows after update is successful
              this.newRows = [];
              this.toggleNewRow = [];
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        } else {
            const tempDocRef = db.collection("TempDelegationsordning").doc(this.currentTabellId);
          tempDocRef.get().then((doc) => {
            if (doc.exists) {
              let tempEntry = doc.data();
              tempEntry.rows[0].allTables[0].table.push(newRow);

              tempDocRef.update(tempEntry)
                .then(() => {
                  console.log("Row added to existing avsnitt/table in TempDelegationsordning");
                })
                .catch((error) => {
                  console.error("Error updating document in TempDelegationsordning: ", error);
                });
            } else {
              tempDocRef.set({
                id: rowId,
                parentId: id,
                title: title,
                type: "new",
                rows: [{
                  allTables: [{
                    id: allTableId,
                    table: [newRow],
                  }],
                }],
              })
              .then(() => {
                console.log("New avsnitt/table created in TempDelegationsordning");
                this.newRows = [];
                this.toggleNewRow = [];
              })
              .catch((error) => {
                console.error("Error creating new document in TempDelegationsordning: ", error);
              });
            }
          })
          .catch((error) => {
            console.error("Error fetching document from TempDelegationsordning: ", error);
          });
        }
      });
      // Add the new row
      this.newRows.push(newRow);
    },
    addEmptyEntryToArrays(tableId, avsnittIndex, tableIndex, rowIndex) {
      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;

          // Add an empty entry to the arendenVidare and vidaredelegat arrays
          if (Array.isArray(rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendenVidare)) {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendenVidare.push("");
          } else {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendenVidare = [""];
          }

          if (Array.isArray(rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vidaredelegat)) {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vidaredelegat.push("");
          } else {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vidaredelegat = [""];
          }

          // Update the document in Firestore
          docRef.update({
            rows: rows
          }).then(() => {
            console.log("Document successfully updated with empty entries!");
          }).catch((error) => {
            console.error("Error updating document: ", error);
          });
        } else {
          console.log("Document not found");
        }
      });
    },
    removeEntryFromArraysTest(tableId, avsnittIndex, tableIndex, rowIndex){
      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;

          // Add an empty entry to the arendenVidare and vidaredelegat arrays
          if (Array.isArray(rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendenVidare)) {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendenVidare.pop();
            this.temporaryArendenVidare.pop();
          } else {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendenVidare = [""];
          }

          if (Array.isArray(rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vidaredelegat)) {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vidaredelegat.pop();
            this.temporaryVidareDelegat.pop();
          } else {
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vidaredelegat = [""];
          }

          // Update the document in Firestore
          docRef.update({
            rows: rows
          }).then(() => {
            console.log("Document successfully updated with empty entries!");
          }).catch((error) => {
            console.error("Error updating document: ", error);
          });
        } else {
          console.log("Document not found");
        }
      });
    },
    editRow(tableId, tableTitle, avsnittIndex, tableIndex, rowIndex, rowTableId, avsnittId, tabellId) {
      this.isVisible = false;
      this.currentRowIndex = rowIndex + rowTableId;
      this.editingRow = {
        tableId,
        tableTitle,
        avsnittIndex,
        tableIndex,
        rowIndex,
        avsnittId,
        tabellId
      };

      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          //console.log(rows);
          //console.log(rows[avsnittIndex].allTables[tableIndex].table[rowIndex]);
          this.editId = rows[avsnittIndex].allTables[tableIndex].table[rowIndex].id
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].id
            : "";
          this.editDelegat = rows[avsnittIndex].allTables[tableIndex].table[rowIndex].delegat
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].delegat
            : "";
          this.editNr = rows[avsnittIndex].allTables[tableIndex].table[rowIndex].nr
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].nr
            : "";
          this.editArendetyp = rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendetyp
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendetyp
            : "";
          this.editVd = rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vd
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vd
            : "";
          this.editLagrum = rows[avsnittIndex].allTables[tableIndex].table[rowIndex].lagrum
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].lagrum
            : "";
          this.editAnmarkning = rows[avsnittIndex].allTables[tableIndex].table[rowIndex].anmarkning
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].anmarkning
            : "";
          this.editArendenVidare = rows[avsnittIndex].allTables[tableIndex].table[rowIndex]
            .arendenVidare
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].arendenVidare
            : "";
          this.editVidaredelegat = rows[avsnittIndex].allTables[tableIndex].table[rowIndex]
            .vidaredelegat
            ? rows[avsnittIndex].allTables[tableIndex].table[rowIndex].vidaredelegat
            : "";

            this.temporaryArendenVidare = [...this.editArendenVidare];
            this.temporaryVidareDelegat = [...this.editVidaredelegat];
        } else {
          console.log("Document not found");
        }
      });
    },
    cancelEdit() {
      this.editingRow = null;
      this.isVisible = true;
      this.toggleNewRow = [];
      this.temporaryArendenVidare = [];
      this.temporaryVidareDelegat = [];
      this.editArendenVidare = [];
      this.editVidaredelegat = [];
      this.calculateHeight();
    },
    /* async saveNotPublishedRow() {
      this.isVisible = true;
      const { tableId, avsnittIndex, tableIndex, rowIndex, tableTitle } = this.editingRow;

      // Commit changes from temporary arrays to actual edit variables
      this.editArendenVidare = [...this.temporaryArendenVidare];
      this.editVidaredelegat = [...this.temporaryVidareDelegat];

      const editRow = {
        id: this.editId,
        delegat: this.editDelegat,
        nr: this.editNr,
        arendetyp: this.editArendetyp,
        vd: this.editVd,
        lagrum: this.editLagrum,
        anmarkning: this.editAnmarkning,
        arendenVidare: this.editArendenVidare,
        vidaredelegat: this.editVidaredelegat,
      };

      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      try {
        const doc = await docRef.get();

        if (doc.exists) {
          const rows = doc.data().rows;
          const originalRow = { ...rows[avsnittIndex].allTables[tableIndex].table[rowIndex] }; // copy of the original row
          rows[avsnittIndex].allTables[tableIndex].table[rowIndex] = editRow;

          const changedTd = [];
            Object.keys(editRow).forEach(key => {
              // Check if the property is an array
              if (Array.isArray(editRow[key]) && Array.isArray(originalRow[key])) {
                // Compare stringified versions of arrays
                if (JSON.stringify(editRow[key]) !== JSON.stringify(originalRow[key])) {
                  changedTd.push(key);
                }
              } else {
                // For non-array properties
                if (editRow[key] !== originalRow[key]) {
                  changedTd.push(key);
                }
              }
            });

          await docRef.update({ rows });

          console.log("Row updated successfully!");
          this.cancelEdit();
          this.addVersion(doc.data().title);

          // Now create or update the temporary document
          const tempDocRef = db.collection("TempDelegationsordning").doc(this.editId);
          const tempDoc = await tempDocRef.get();

          if (tempDoc.exists) { // If document exists, update it
            const oldChangedTd = tempDoc.data().changedTd || [];
            const updatedChangedTd = [...new Set([...oldChangedTd, ...changedTd])]; // Combine oldChangedTd and changedTd, remove duplicates

            await tempDocRef.update({
              changedTd: updatedChangedTd,
              rows: [{
                allTables: [
                  {
                    table: [editRow],
                  },
                ],
              }],
            });
          } else { // Else, create a new document
            await tempDocRef.set({
              id: this.editId,
              parentId: tableId,
              title: tableTitle,
              changedTd: changedTd,
              rows: [{
                allTables: [
                  {
                    table: [editRow],
                  },
                ],
              }],
            });
          }

          console.log("Temporary document updated/created successfully!");
          const element = this.$refs[this.editId];
          // Add a class to the DOM element
          element[0].classList.add("changed");

          this.editingRow = null;
          this.fetchChanges();
          await this.loadTempDelegations();
          this.reloadTable();
          this.calculateHeight();
        } else {
          console.log("Document not found");
        }
      } catch (error) {
        console.error("Error occurred: ", error);
      }
    }, */
    async saveEditBoth() {
      this.isVisible = true;
      const { tableId, avsnittIndex, tableIndex, rowIndex, tableTitle, avsnittId, tabellId } = this.editingRow;

      // Commit changes from temporary arrays to actual edit variables
      this.editArendenVidare = [...this.temporaryArendenVidare];
      this.editVidaredelegat = [...this.temporaryVidareDelegat];

      const editRow = {
        id: this.editId,
        delegat: this.editDelegat,
        nr: this.editNr,
        arendetyp: this.editArendetyp,
        vd: this.editVd,
        lagrum: this.editLagrum,
        anmarkning: this.editAnmarkning,
        arendenVidare: this.editArendenVidare,
        vidaredelegat: this.editVidaredelegat,
      };

      await this.updateOrAddRowInTempDelegation(tableId, avsnittIndex, tableIndex, rowIndex, editRow, tableTitle, avsnittId, tabellId);

      this.finalizeEditProcess();
    },

    async updateRowInCollection(collectionName, tableId, avsnittIndex, tableIndex, rowIndex, editRow) {
      const docRef = db.collection(collectionName).doc(tableId);
      const doc = await docRef.get();
      if (!doc.exists) {
        console.log(`Document not found in ${collectionName}`);
        return [];
      }

      const rows = doc.data().rows;
      const originalRow = { ...rows[avsnittIndex].allTables[tableIndex].table[rowIndex] }; // Ensure this is the correct original row
      rows[avsnittIndex].allTables[tableIndex].table[rowIndex] = editRow;

      await docRef.update({ rows });
      console.log("Row updated successfully!");

      return this.identifyChangedFields(originalRow, editRow);
    },

    identifyChangedFields(originalRow, newRow) {
      const changedFields = [];
      Object.keys(newRow).forEach(key => {
        if (JSON.stringify(newRow[key]) !== JSON.stringify(originalRow[key])) {
          changedFields.push(key);
        }
      });
      return changedFields;
    },

    async updateOrAddRowInTempDelegation(tableId, avsnittIndex, tableIndex, rowIndex, editRow, tableTitle, avsnittId, tabellId) {

      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      const doc = await docRef.get();

      const tempDocRef = db.collection("TempDelegationsordning").doc(tableId);
      const tempDoc = await tempDocRef.get();

      if (tempDoc.exists) {
        const tempRows = tempDoc.data().rows;
        tempRows[avsnittIndex].allTables[tableIndex].table[rowIndex] = editRow;
        await tempDocRef.update({ rows: tempRows });

        const rows = doc.data().rows;
        const originalRow = { ...rows[avsnittIndex].allTables[tableIndex].table[rowIndex] }; // copy of the original row
        rows[avsnittIndex].allTables[tableIndex].table[rowIndex] = editRow;

        const changedTd = [];
        Object.keys(editRow).forEach(key => {
          // Check if the property is an array
          if (Array.isArray(editRow[key]) && Array.isArray(originalRow[key])) {
            // Compare stringified versions of arrays
            if (JSON.stringify(editRow[key]) !== JSON.stringify(originalRow[key])) {
              changedTd.push(key);
            }
          } else {
            // For non-array properties
            if (editRow[key] !== originalRow[key]) {
              changedTd.push(key);
            }
          }
        });

        await docRef.update({ rows });

        console.log("Row updated successfully!");
        this.cancelEdit();
        this.addVersion(doc.data().title);
      } else {
        const tempAvsnittDocRef = db.collection("TempDelegationsordning").doc(avsnittId);
        const tempAvsnittDoc = await tempAvsnittDocRef.get();

        if (tempAvsnittDoc.exists) {
          if (doc.exists) {
            const rows = doc.data().rows;
            const originalRow = { ...rows[avsnittIndex].allTables[tableIndex].table[rowIndex] }; // copy of the original row
            rows[avsnittIndex].allTables[tableIndex].table[rowIndex] = editRow;

            const changedTd = [];
            Object.keys(editRow).forEach(key => {
              // Check if the property is an array
              if (Array.isArray(editRow[key]) && Array.isArray(originalRow[key])) {
                // Compare stringified versions of arrays
                if (JSON.stringify(editRow[key]) !== JSON.stringify(originalRow[key])) {
                  changedTd.push(key);
                }
              } else {
                // For non-array properties
                if (editRow[key] !== originalRow[key]) {
                  changedTd.push(key);
                }
              }
            });

            await docRef.update({ rows });

            console.log("Row updated successfully!");
            this.cancelEdit();
            this.addVersion(doc.data().title);
          }
          const tempRowsAvsnitt = tempAvsnittDoc.data().rows;
          tempRowsAvsnitt[0].allTables[tableIndex].table[rowIndex] = editRow;
          await tempAvsnittDocRef.update({ rows: tempRowsAvsnitt });
        } else {
          const tempAvsnittTableDocRef = db.collection("TempDelegationsordning").doc(tabellId);
          const tempAvsnittTableDoc = await tempAvsnittTableDocRef.get();

          if (tempAvsnittTableDoc.exists) {
            if (doc.exists) {
              const rows = doc.data().rows;
              const originalRow = { ...rows[avsnittIndex].allTables[tableIndex].table[rowIndex] }; // copy of the original row
              rows[avsnittIndex].allTables[tableIndex].table[rowIndex] = editRow;

              const changedTd = [];
              Object.keys(editRow).forEach(key => {
                // Check if the property is an array
                if (Array.isArray(editRow[key]) && Array.isArray(originalRow[key])) {
                  // Compare stringified versions of arrays
                  if (JSON.stringify(editRow[key]) !== JSON.stringify(originalRow[key])) {
                    changedTd.push(key);
                  }
                } else {
                  // For non-array properties
                  if (editRow[key] !== originalRow[key]) {
                    changedTd.push(key);
                  }
                }
              });

              await docRef.update({ rows });

              console.log("Row updated successfully!");
              this.cancelEdit();
              this.addVersion(doc.data().title);
            }

            const tempRowsAvsnittTable = tempAvsnittTableDoc.data().rows;
            tempRowsAvsnittTable[0].allTables[0].table[rowIndex] = editRow;
            await tempAvsnittTableDocRef.update({ rows: tempRowsAvsnittTable });
          } else {

            if (doc.exists) {
              const rows = doc.data().rows;
              const originalRow = { ...rows[avsnittIndex].allTables[tableIndex].table[rowIndex] }; // copy of the original row
              rows[avsnittIndex].allTables[tableIndex].table[rowIndex] = editRow;

              const changedTd = [];
              Object.keys(editRow).forEach(key => {
                // Check if the property is an array
                if (Array.isArray(editRow[key]) && Array.isArray(originalRow[key])) {
                  // Compare stringified versions of arrays
                  if (JSON.stringify(editRow[key]) !== JSON.stringify(originalRow[key])) {
                    changedTd.push(key);
                  }
                } else {
                  // For non-array properties
                  if (editRow[key] !== originalRow[key]) {
                    changedTd.push(key);
                  }
                }
              });

              await docRef.update({ rows });

              console.log("Row updated successfully!");
              this.cancelEdit();
              this.addVersion(doc.data().title);

              // Now create or update the temporary document
              const tempDocRef = db.collection("TempDelegationsordning").doc(this.editId);
              const tempDoc = await tempDocRef.get();

              if (tempDoc.exists) { // If document exists, update it
                const oldChangedTd = tempDoc.data().changedTd || [];
                const updatedChangedTd = [...new Set([...oldChangedTd, ...changedTd])]; // Combine oldChangedTd and changedTd, remove duplicates

                await tempDocRef.update({
                  changedTd: updatedChangedTd,
                  rows: [{
                    allTables: [
                      {
                        table: [editRow],
                      },
                    ],
                  }],
                });
              } else { // Else, create a new document
                await tempDocRef.set({
                  id: this.editId,
                  parentId: tableId,
                  title: tableTitle,
                  changedTd: changedTd,
                  rows: [{
                    allTables: [
                      {
                        table: [editRow],
                      },
                    ],
                  }],
                });
              }

              console.log("Temporary document updated/created successfully!");
              const element = this.$refs[this.editId];
              // Add a class to the DOM element
              element[0].classList.add("changed");

              this.editingRow = null;
              this.fetchChanges();
              await this.loadTempDelegations();
              this.reloadTable();
              this.calculateHeight();
            } else {
              console.log("Document not found");
            }
          }
        }
      }
      console.log("Temporary document updated or created successfully!");
    },

    finalizeEditProcess() {
      // Reset editing state and UI
      this.cancelEdit();
      this.fetchChanges();
      this.loadTempDelegations();
      this.reloadTable();
      this.calculateHeight();
      // Additional finalization steps (e.g., refresh data, reload table, etc.)
    },
    async deleteTable(tableId, parentId) {
      const confirmed = confirm("Är du säker på att du vill ta bort detta tabell?");

      if (confirmed) {
        const docRef = db.collection('PendingDelegationsordning').doc(parentId);

        docRef.get()
        .then(doc => {
          if (doc.exists) { // Check if document exists
            const data = doc.data();
            if (data && data.rows) { // Check if data and rows exist
              const oldRows = data.rows;
              const newRows = oldRows.map(row => {
                const newAllTables = row.allTables.filter(tableGroup => tableGroup.id !== tableId); // Filter out the table with the specific id
                return { ...row, allTables: newAllTables };
              });

              docRef.update({
                rows: newRows
              })
              .then(() => {
                console.log('Table deleted successfully!');
                this.fetchChanges(); // Refresh the changes
                this.reloadTable(); // Reload the table
              })
              .catch(error => {
                console.error('Error updating data:', error);
              });
            } else {
              console.error('Document does not have rows field:', parentId);
            }
          } else {
            console.error('Document does not exist:', parentId);
          }
        })
        .catch(error => {
          console.error('Error getting document:', error);
        });
      }
    },
    deleteAvsnitt(tableId, avsnittId, avsnittIndex) {
      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          const tableToUpdate = rows[avsnittIndex]

          if (tableToUpdate) {
            const tempDocRef = db.collection("TempDelegationsordning").doc(avsnittId);
            tempDocRef.get().then((tempDoc) => {
              if (tempDoc.exists) {
                // If exists in TempDelegationsordning, delete from both collections
                tempDocRef.delete().then(() => {
                  console.log("Table deleted from TempDelegationsordning");
                  // Remove from PendingDelegationsordning and update
                  rows.splice(avsnittIndex, 1);
                  docRef.update({ rows })
                    .then(() => console.log("Table removed from PendingDelegationsordning"))
                    .catch((error) => console.error("Error updating PendingDelegationsordning: ", error));
                }).catch((error) => {
                  console.error("Error deleting from TempDelegationsordning: ", error);
                });
              } else {
                // If not in TempDelegationsordning, mark as deleted in TempDelegationsordning
                tempDocRef.set({
                  id: tableToUpdate.id,
                  parentId: tableId,
                  title: "Deleted Table",
                  type: "delete-table",
                  // Add any other necessary fields here
                }).then(() => {
                  console.log("Deleted table marked in TempDelegationsordning");
                  // Also, update the PendingDelegationsordning to mark the table as deleted
                  tableToUpdate.type = "delete-table";
                  docRef.update({ rows })
                    .then(() => console.log("Table marked as deleted in PendingDelegationsordning"))
                    .catch((error) => console.error("Error updating PendingDelegationsordning: ", error));
                }).catch((error) => {
                  console.error("Error creating deleted table marker in TempDelegationsordning: ", error);
                });
              }
            }).catch((error) => {
              console.error("Error fetching document from TempDelegationsordning: ", error);
            });
          } else {
            console.log("Table not found");
          }
        } else {
          console.log("Document not found in PendingDelegationsordning");
        }
      }).catch((error) => {
        console.error("Error fetching document from PendingDelegationsordning: ", error);
      });
    },
    deleteDocument(docId) {
      if (confirm("Vill du verkligen ta bort denna delegationsordning?")) {
        db.collection("PendingDelegationsordning")
          .doc(docId)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
        db.collection("Delegationsordning")
          .doc(docId)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }
    },
    deleteRow(tableId, avsnittIndex, tableIndex, rowIndex, rowId, tableTitle) {
      const docRef = db.collection("PendingDelegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          const rowToCopy = rows[avsnittIndex].allTables[tableIndex].table[rowIndex]; // get row to copy

          const deleDocRef = db.collection("TempDelegationsordning").doc(tableId);
            deleDocRef.get().then((doc) => {
              if (doc.exists) {
              const tempRows = doc.data().rows;
              rows[avsnittIndex].allTables[tableIndex].table.splice(rowIndex, 1);
              tempRows[avsnittIndex].allTables[tableIndex].table.splice(rowIndex, 1);

              deleDocRef.update({
                rows: tempRows
              })
              docRef.update({
                rows: rows
              })
        } else {
          // Create corresponding document in TempDelegationsordning
          const tempDocRef = db.collection("TempDelegationsordning").doc(rowId);
          tempDocRef.set({
            id: rowId,
            parentId: tableId,
            title: tableTitle, // use properties from rowToCopy
            delete: true,
            rows: [{
              allTables: [
                {
                  id: rowToCopy.id,
                  table: [rowToCopy], // copied row
                },
              ],
            }],
          }).then(() => {
            console.log("Row copied to TempDelegationsordning successfully!");
            this.fetchChanges(); // Refresh the changes
            this.reloadTable(); // Reload the table
          }).catch((error) => {
            console.error("Error creating document in TempDelegationsordning: ", error);
          });
          }
        });
        } else {
          console.log("Document not found");
        }
      });
    },
    fetchChanges() {
      // Fetch all changes from Firestore collection "TempDelegationsordning"
      db.collection("TempDelegationsordning")
        .get()
        .then((querySnapshot) => {
          const changes = [];
          querySnapshot.forEach((doc) => {
            changes.push(doc.data());
          });
          this.changes = changes;
          console.log("Changes fetched successfully!");
          this.calculateHeight();
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    },
    hasChanges(id) {
      // Check if the current row has any changes and if the type is 'new'
      const hasNewChanges = this.changes.some((change) => change.id === id && !change.type);
      return hasNewChanges;
    },
    hasNewChanges(id) {
      // Check if the current row has any changes and if the type is 'new'
      const hasNewChanges = this.changes.some((change) => change.id === id && change.type /* change.type === 'new' */);
      return hasNewChanges;
    },
    hasRemovedChanges(id) {
      // Check if the current row has any changes and if the type is 'new'
      const hasNewChanges = this.changes.some((change) => change.id === id && change.delete /* change.type === 'new' */);
      return hasNewChanges;
    },
    hasTdChanges(rowId, field) {
        if (this.tempDelegations[rowId]) {
            const changedTd = this.tempDelegations[rowId].changedTd || [];
            return changedTd.includes(field);
        }
        return false;
    },
    fetchUsers() {
      // Fetch all users from Firestore collection "Delegater"
      db.collection("Delegater").orderBy("titel")
        .get()
        .then((querySnapshot) => {
          const users = [];
          querySnapshot.forEach((doc) => {
            users.push(doc.data());
          });
          this.users = users;
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    },
    setCurrentEditingIndexAvsnitt(avsnittIndex, value) {
      this.currentEditingIndex = avsnittIndex;
      this.avsnittFritextValue = value;
    },
    setCurrentEditingIndexTabell(avsnittIndex, value) {
      this.currentEditingIndex = avsnittIndex;
      this.tabellFritextValue = value;
    },
    fritextAvsnitt(avsnittIndex, fritext, tableId) {
      const delegationsRef = db.collection("PendingDelegationsordning"); // Eller "PendingDelegationsordning" beroende på vilken samling du vill uppdatera

      const docId = tableId; // Använd det faktiska dokument-ID:t
      delegationsRef.doc(docId).get().then(doc => {
        if (doc.exists) {
          const data = doc.data(); // Hämta dokumentdata
          // Kontrollera om 'fritext' för det specifika avsnittet redan finns
          if (!data.rows[avsnittIndex].fritext) {
            // Fritextsfältet finns inte, lägg till det nya fritextet
            data.rows[avsnittIndex].fritext = fritext;
          } else {
            // Fritextsfältet finns, uppdatera befintligt fritext
            data.rows[avsnittIndex].fritext = fritext;
          }
          // Uppdatera dokumentet med den nya datan
          delegationsRef.doc(docId).update(data).then(() => {
            console.log("Fritextsfältet har uppdaterats!");
            alert("Fritextsfältet för avsnittet har sparats/uppdaterats!");
          }).catch(error => {
            console.error("Error updating document: ", error);
          });
        } else {
          console.log("Document not found");
        }
      }).catch(error => {
        console.error("Error getting document: ", error);
      });
    },
    fritextTabell(avsnittIndex, tableIndex, fritext, tableId) {
      const delegationsRef = db.collection("PendingDelegationsordning"); // Eller "PendingDelegationsordning" beroende på vilken samling du vill uppdatera

      const docId = tableId; // Använd det faktiska dokument-ID:t
      delegationsRef.doc(docId).get().then(doc => {
        if (doc.exists) {
          const data = doc.data(); // Hämta dokumentdata
          // Kontrollera om 'fritext' för det specifika avsnittet redan finns
          if (!data.rows[avsnittIndex].allTables[tableIndex].fritext) {
            // Fritextsfältet finns inte, lägg till det nya fritextet
            data.rows[avsnittIndex].allTables[tableIndex].fritext = fritext;
          } else {
            // Fritextsfältet finns, uppdatera befintligt fritext
            data.rows[avsnittIndex].allTables[tableIndex].fritext = fritext;
          }
          // Uppdatera dokumentet med den nya datan
          delegationsRef.doc(docId).update(data).then(() => {
            console.log("Fritextsfältet har uppdaterats!");
            alert("Fritextsfältet för avsnittet har sparats/uppdaterats!");
          }).catch(error => {
            console.error("Error updating document: ", error);
          });
        } else {
          console.log("Document not found");
        }
      }).catch(error => {
        console.error("Error getting document: ", error);
      });
    },
    updateAvsnitt(index, newAvsnitt, tableId) {
      const delegationsRef = db.collection("PendingDelegationsordning");

      const docId = tableId; // Replace this with the actual document ID
      delegationsRef
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // Document found, update the specific row
            const rows = doc.data().rows;
            rows[index].avsnitt = newAvsnitt[index]; // Update the avsnitt property of the specific row
            // Call the update method with the updated document data
            return delegationsRef.doc(docId).update({ rows: rows });
          } else {
            console.log("Document not found");
          }
        })
        .then(() => {
          console.log("Document successfully updated!");
          alert("Avsnittet har uppdaterats!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
        const publicDocRef = db.collection("Delegationsordning");

        const publicDocId = tableId; // Replace this with the actual document ID
        publicDocRef
          .doc(publicDocId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              // Document found, update the specific row
              const rows = doc.data().rows;
              rows[index].avsnitt = newAvsnitt[index]; // Update the avsnitt property of the specific row
              // Call the update method with the updated document data
              return publicDocRef.doc(publicDocId).update({ rows: rows });
            } else {
              console.log("Document not found");
            }
          })
          .then(() => {
            console.log("Document successfully updated!");
            alert("Avsnittet har uppdaterats!");
          })
          .catch((error) => {
            console.error("Error updating document: ", error);
          });
    },
    updateTitle(tableId, newTitle) {
        // Logic to update the title in the database
        const tableRef = db.collection('PendingDelegationsordning').doc(tableId);
        tableRef.update({ title: newTitle })
          .then(() => {
            console.log('Title updated successfully');
            // Additional logic if needed
          })
          .catch(error => {
            console.error('Error updating title: ', error);
          });
      },
    updateRubrik(avsnittIndex, index, newRubrik, tableId) {
        const pendingDelegationsRef = db.collection("PendingDelegationsordning");
        const delegationsRef = db.collection("Delegationsordning");

        const docId = tableId; // Replace this with the actual document ID

        // A function to update a specific collection
        const updateCollection = (collectionRef) => {
            collectionRef
                .doc(docId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        // Document found, update the specific row
                        const rows = doc.data().rows;
                        rows[avsnittIndex].allTables[index].id = newRubrik[avsnittIndex + index]; // Update the avsnitt property of the specific row
                        // Call the update method with the updated document data
                        return collectionRef.doc(docId).update({ rows: rows });
                    } else {
                        console.log("Document not found in collection:", collectionRef.id);
                    }
                })
                .then(() => {
                    console.log("Document successfully updated in collection:", collectionRef.id);
                })
                .catch((error) => {
                    console.error("Error updating document in collection:", collectionRef.id, error);
                });
        };

        // Update both collections
        updateCollection(pendingDelegationsRef);
        updateCollection(delegationsRef);
        alert("Rubriken har uppdaterats!");
    },
    matchDelegat(delegatTitel) {
      this.currentDelegat = delegatTitel;

        const delegations = this.$store.state.delegations;

        // Töm eventuella tidigare resultat
        this.matchVd = [];
        this.matchDelegations = [];

        delegations.forEach((delegation) => {
          delegation.rows?.forEach((avsnitt) => {
            avsnitt.allTables?.forEach((allTable) => {
              allTable.table?.forEach((table) => {
                if (table.vidaredelegat?.includes(delegatTitel)) {
                  const delegationMatch = { 
                    id: delegation.id, 
                    delegationsordning: delegation.title, 
                    avsnitt: avsnitt.avsnitt, 
                    tabell: allTable.id, 
                    nr: table.nr, 
                    arendetyp: table.arendetyp 
                  };
                  this.matchVd.push(delegationMatch);
                }
                if (table.delegat === delegatTitel) {
                  const delegationMatch = {
                    id: delegation.id, 
                    delegationsordning: delegation.title, 
                    avsnitt: avsnitt.avsnitt, 
                    tabell: allTable.id, 
                    nr: table.nr, 
                    arendetyp: table.arendetyp 
                  };
                  this.matchDelegations.push(delegationMatch);
                    }
                  });
                });
              });
            });
            console.log(this.matchDelegations);
    },
    addDelegation(doc, row, tableGroup, table, targetArray) {
      const delegation = {
        id: doc.id,
        delegationsordning: doc.data().title,
        avsnitt: row.avsnitt,
        rubrik: tableGroup.id,
        nr: table.nr,
        arendeNr: table.nr,
        arendetyp: table.arendetyp,
        vidaredelegat: table.vidaredelegat,
      };
      this[targetArray].push(delegation);
    },

    onHidden() {
      this.matchDelegations = [];
      this.matchVd = [];
    },
    copyToDelegationsordning(id) {
      const docId = id; // Replace with the ID of the document you want to copy

      // Get the document from "PendingDelegationsordning" collection
      db.collection("PendingDelegationsordning")
        .doc(docId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();

            // Create a new document in "Delegationsordning" collection with the same ID and data
            db.collection("Delegationsordning")
              .doc(docId)
              .set(data)
              .then(() => {
                console.log("Document copied successfully!");
              })
              .catch((error) => {
                console.error("Error copying document: ", error);
              });
          } else {
            console.log("Document not found in 'PendingDelegationsordning'");
          }
        })
        .catch((error) => {
          console.error("Error getting document: ", error);
        });
    },
    openReport(id, avsnitt, title) {
      this.$bvModal.show(id);
      this.reported.title = title;
      this.reported.avsnitt = avsnitt;
      this.reported.tabell = id;
    },
    closeReport(id) {
      this.$bvModal.hide(id);
      this.showMessage("Din ändring har registrerats!");
    },
    async submitForm() {
        // Add new document to "Reported" collection
        const reportedRef = db.collection("Reported");
        const timestamp = new Date().getTime();
        const newReport = {
            changes: {
            avsnitt: this.reported.avsnitt,
            message: this.reported.message,
            tabell: this.reported.tabell,
            title: this.reported.title,
            id: timestamp.toString()
            }
        };
        await reportedRef.add(newReport);

        // Reset form and set submitted flag
        this.reported.avsnitt = "";
        this.reported.message = "";
        this.reported.tabell = "";
        this.reported.title = "";
        this.submitted = true;
    },
  },
};
</script>
<style scoped>
a {
  color: #00627d;
  text-decoration: none;
}

td {
  cursor: grab;
}

.card {
  padding: 0px;
}

/deep/ .card-header {
  background-color: #edf2f7 !important;
  border-bottom: 1px solid #e7eef5 !important;
}

.toolbar-wrapper {
  background: #f4f7fa;
  border-radius: 10px;
  padding: 10px 15px;
  border: 1px solid #e7eef5;
}

.edit-button {
  position: fixed;
  z-index: +999;
  bottom: 20px;
  right: 20px;
  background: #b44595 !important;
  border: 0px;
}

.toc-button {
  position: fixed;
  z-index: +9999;
  bottom: 20px;
  border: 0px;
}

.export-button {
  position: fixed;
  z-index: +9999;
  bottom: 20px;
  margin-left: 200px;
  border: 0px;
}

table .form-control {
  /*min-width: 110px;*/
  min-height: 150px;
}

td label {
  width: 100%;
}

/*
table td:first-child .form-control,
table td:nth-child(5) .form-control {
  min-width: 50px;
} */
table td:nth-child(3) .form-control,
table td:nth-child(7) .form-control,
table td:nth-child(8) .form-control,
table td:nth-child(4) .form-control {
  min-height: auto;
}

/*
table td:nth-child(2) .form-control,
table td:nth-child(6) .form-control {
  min-width: 210px;
}

table td:nth-child(3) .form-control,
table td:nth-child(8) .form-control {
  min-width: 150px;
  min-height: auto;
} */

table td,
table th,
table .form-control {
  font-size: 13px;
}

.rader div:nth-child(odd) {
  width: 35%;
  padding: 1%;
  display: flex;
  align-items: center;
  font-weight: bold;
  border-top: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
}

.rader div:nth-child(even) {
  width: 65%;
  padding: 1%;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

.rader:last-child div {
  border-bottom: 1px solid #e3e3e3;
}

*:focus {
  outline: 4px dashed darkorange;
}

.list-group ul {
  list-style-type: none;
}

.sidemenu {
  position: fixed;
  top: 35px;
  right: 0;
  height: 100vh;
  background-color: white;
  padding: 20px;
  overflow-y: scroll;
  z-index: 999;
  width: 40%;
}

.changed td:first-child:after {
  content: "Pågående ändringar";
  display: block;
  background: #5d0096;
  color: white;
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  left: -1px;
  top: -1px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.newChanged td:first-child:after {
  content: "Tillagda ändringar";
  display: block;
  background: #b44595;
  color: white;
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  left: -1px;
  top: -1px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pendingDelete td:first-child:after {
  content: "Pågående borttagningar";
  display: block;
  background: #b44545;
  color: white;
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  left: 0px;
  top: -1px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.newChanged, .changed {
  position: relative;
}

.changed table {
  border: 1px solid #5d0096;
  padding-top: 40px !important;
}

.changed td {
  border: 1px solid #5d0096;
  padding-top: 40px !important;
}

.pendingDelete td {
  border: 1px solid #b44545;
  padding-top: 40px !important;
}

.newChanged td {
  border: 1px solid #b44595;
  color: rgb(222, 0, 0)!important;
  padding-top: 40px !important;
}

.tdchange {
  color: rgb(222, 0, 0)!important;
}

.pendingDelete td {
  text-decoration: line-through;
  text-decoration-color: red;
}

.editingDelegation {
  position: relative;
  border: 1px solid #b44595;
  padding-top: 30px;
  float:left;
  width: 100%;
}

.editingDelegationTable {
  position: relative;
  border: 1px solid #b44595;
  padding: 15px;
  padding-top: 50px;
}

.editingDelegationRow {
  position: relative;
  border: 1px solid #b44595;
}

.editingDelegationRow td {
  border: 1px solid #b44595;
  padding-top: 50px;
}


.editingDelegationRow td:first-child:before, .editingDelegation:after, .editingDelegationTable:after {
  content: "Tillagda ändringar";
  display: block;
  background: #b44595;
  color: white;
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  left: -1px;
  top: -1px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.new-avsnitt {
    position: relative;
    border: 1px solid #b44595;
    padding: 10px;
    padding-top: 30px;
}

.new-avsnitt:after {
  content: "Tillagda ändringar";
  display: block;
  background: #b44595;
  color: white;
  font-weight: 600;
  font-size: 13px;
  position: absolute;
  left: -1px;
  top: -1px;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.om-dele {
  margin-top: 30px;
  padding: 15px;
}

.ck.ck-editor {
  margin-top: 15px;
  padding: 30px;
}

.arendenArray, .vidareArray {
    display: block;
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
    min-height: 100px;
}

.arendenArray:first-child, .vidareArray:first-child {
    padding:0px 0px 10px 0px;
}

.arendenArray:last-child, .vidareArray:last-child {
    border-bottom: 0px;
}

/deep/ .ck-toolbar {
  position: sticky;
  top: 0;
  z-index: 99999;
  border-color: #e7eef5 !important;
  background: #f4f7fa !important;
}

.ck-wrapper {
  border: 1px solid #e7eef5;
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 10px;
}

/deep/ .om-dele table,
.om-dele td {
  border: 1px solid #e3e3e3;
}

/deep/ .om-dele td {
  padding: 5px;
  border-width: 1px!important;
}

/deep/ .om-dele p {
  margin-bottom: 7.5px;
}

.popover-header {
    font-size: 15px!important;
}

.modal-content a {
    color: #212529!important;
    text-decoration: underline;
    background: transparent;
    font-weight: normal;
}

.modal-content .router-link-exact-active {
    opacity:0.4;
    text-decoration:none!important;
}

.modal-content td:last-child, .modal-content td:first-child {
    width: 25%;
}

.modal-content td:nth-child(2) {
    width: 20%;
}

.modal-content td:nth-child(3) {
    width: 10%;
}

.modal-content td:nth-child(4) {
    width: 10%;
}

.fritext {
  background: white;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}
</style>
