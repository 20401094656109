import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyCNQGyNShyUTbCWFPDFfxIdn9Q6b0SQMf8",
  authDomain: "delegationsportalen.firebaseapp.com",
  projectId: "delegationsportalen",
  storageBucket: "delegationsportalen.appspot.com",
  messagingSenderId: "537346964631",
  appId: "1:537346964631:web:1e864405c638f01bcd7dd2",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export Firebase services
export const db = firebase.firestore();
export const auth = firebase.auth();

// Export Firebase itself in case you need the whole library
export default firebase;