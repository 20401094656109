<template>
    <div style="text-align:left;padding: 30px;">
        <div class="card">
            <h3>Skapa en ny delegationsordning</h3>
            <div class="entry-form">
                <b-form @submit.prevent="submitForm">
                    <label class="mt15" for="titel">Titel</label>
                    <b-form-input type="text" id="titel" v-model="title"/>
                    <label class="mt15" for="titel">Om delegationsordning</label>
                    <ckeditor :editor="editor" v-model="about" :config="editorConfig" class="om-dele" @ready="onEditorReady">
                    </ckeditor>
                    <label class="mt15" for="avsnitt">Avsnitt</label>
                    <b-form-input type="text" id="titel" v-model="avsnitt"/>
                    <label class="mt15" for="tabell">Rubrik för tabell</label>
                    <b-form-input type="text" id="titel" v-model="tabell"/>
                    <table class="table table-striped table-bordered mt30" tabindex="0" style="display: none;">
                        <thead style="background: #00627d; color: white">
                        <tr>
                            <th style="width: 5%">Nr</th>
                            <th style="width: 20%">Ärendetyp</th>
                            <th style="width: 10%">Delegat</th>
                            <th style="width: 6%">v.d.</th>
                            <th style="width: 6%">Lagrum</th>
                            <th style="width: 19%">Anmärkning</th>
                            <th style="width: 14%">Ärenden som vidaredelegeras</th>
                            <th style="width: 10%">Vidaredelegat</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.1.1</td>
                                <td><b-form-input type="text" id="titel" v-model="arendetyp"/></td>
                                <td>
                                  <b-select v-model="delegat" class="custom-select form-control">
                                    <option><b-button v-b-modal.modal-6>+ Lägg till ny delegat</b-button></option>
                                    <option v-for="user in users" :key="user.key" :value="user.titel">
                                    {{ user.titel }}
                                    </option>
                                  </b-select>
                                </td>
                                <td>
                                  <b-select v-model="vd" class="custom-select form-control">
                                    <option>
                                      Nej
                                    </option>
                                    <option>
                                      Ja
                                    </option>
                                  </b-select>
                                </td>
                                <td><b-form-input type="text" id="titel" v-model="lagrum"/></td>
                                <td><b-form-input type="text" id="titel" v-model="anmarkning"/></td>
                                <td>
                                  <b-select v-model="arendenVidare" class="custom-select form-control">
                                    <option>
                                      Vidaredelegeras ej
                                    </option>
                                    <option>
                                      Vidaredelegeras
                                    </option>
                                  </b-select>
                                </td>
                                <td>
                                  <b-select v-model="vidareDelegat" class="custom-select form-control">
                                    <option v-for="user in users" :key="user.key" :value="user.titel">
                                      {{ user.titel }}
                                    </option>
                                  </b-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <b-button type="submit" value="Submit" class="btn btn-primary" style="margin-top:15px;">Skapa delegationsordning</b-button>
                </b-form>
            </div>
        </div>
    <b-modal id="modal-6" ref="addDelegat-modal" title="Lägg till ny delegat" hide-footer hide-close>
        <form>
        <div class="form-group">
            <label for="titel">Titel</label>
            <input type="text" class="form-control" id="titel" v-model="newUser.titel" />
        </div>
        <button type="button" class="btn btn-primary" style="margin-top:20px;" @click="addUser">Lägg till delegat</button>
        </form>
    </b-modal>
    </div>
</template>
  
<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import '@ckeditor/ckeditor5-build-classic/build/translations/sv';

const Delegationsportalen = {
  apiKey: "AIzaSyCNQGyNShyUTbCWFPDFfxIdn9Q6b0SQMf8",
  authDomain: "delegationsportalen.firebaseapp.com",
  projectId: "delegationsportalen",
  storageBucket: "delegationsportalen.appspot.com",
  messagingSenderId: "537346964631",
  appId: "1:537346964631:web:1e864405c638f01bcd7dd2",
};

firebase.initializeApp(Delegationsportalen);

const db = firebase.firestore();

export default {
  components: {
    //Message,
  },
  data() {
    return {
      editor: DecoupledEditor,
      editorConfig: {
        // todo confg
        language: 'sv',
      },
      about: '',
      title: '',
      avsnitt: '',
      tabell: '',
      arendetyp: '',
      delegat: '',
      vd: '',
      lagrum: '',
      anmarkning: '',
      arendenVidare: '',
      vidareDelegat: '',
      users: [],
      showModal: false,
      newUser: {
        titel: "",
      },
    }
  },
  mounted() {
    this.fetchUsers();
  },
  watch: {
    delegat(newVal) {
      if (newVal === "+ Lägg till ny delegat") {
        this.$nextTick(() => {
          this.$refs["addDelegat-modal"].show();
        });
      }
    },
  },
  methods: {
    addUser() {
      // Add new user to Firestore collection "Delegater"
      db.collection("Delegater")
        .add(this.newUser)
        .then(() => {
          console.log("New user added successfully!");
          // Clear input fields
          this.newUser.titel = "";
          // Fetch updated user list from Firestore
          this.fetchUsers();
          this.$refs["addDelegat-modal"].hide();
        })
        .catch((error) => {
          console.error("Error adding new user: ", error);
        });
    },
    onEditorReady(editor) {
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    },
    fetchUsers() {
      // Fetch all users from Firestore collection "Delegater"
      db.collection("Delegater")
        .get()
        .then((querySnapshot) => {
          const users = [];
          querySnapshot.forEach((doc) => {
            users.push(doc.data());
          });
          this.users = users;
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    },
    submitForm() {
      if (!this.about || !this.title) return;

      const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); // Generate a random unique id
      const rubrikId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); // Generate a random unique id
      const avsnittId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15); // Generate a random unique id
      const timestamp = firebase.firestore.Timestamp.now(); // Current timestamp

      const entryId = id; // Use the same ID for both collections

      const pendingEntry = {
        about: this.about,
        title: this.title,
        id: entryId,
        timestamp: timestamp,
        type: "new-delegationsordning",
        rows: [
          {
            avsnitt: this.avsnitt,
            id: avsnittId,
            allTables: [
              {
                id: this.tabell,
                table: [
                  {
                    id: rubrikId,
                    arendetyp: this.arendetyp,
                    delegat: this.delegat,
                    vd: this.vd,
                    lagrum: this.lagrum,
                    anmarkning: this.anmarkning,
                    arendenVidare: this.arendenVidare,
                    vidareDelegat: this.vidareDelegat,
                  },
                ],
              },
            ],
          },
        ],
      };

      // Add entry to "PendingDelegationsordning" collection
      db.collection("PendingDelegationsordning")
        .doc(entryId)
        .set(pendingEntry)
        .then(() => {
          alert(this.title + " har skapats!");
          // Reset form fields and other variables as needed
          this.about = "";
          this.title = "";
          this.arendetyp = "";
          this.delegat = "";
          this.vd = "";
          this.lagrum = "";
          this.anmarkning = "";
          this.arendenVidare = "";
          this.vidareDelegat = "";
          this.avsnitt = "";
          this.tabell = "";
        })
        .catch((error) => {
          console.error("Error adding new entry to PendingDelegationsordning: ", error);
        });

      // Add entry to "Delegationsordning" collection
      db.collection("TempDelegationsordning")
        .doc(entryId)
        .set(pendingEntry)
        .then(() => {
          // Handle success for "Delegationsordning" collection if needed
        })
        .catch((error) => {
          console.error("Error adding new entry to Delegationsordning: ", error);
        });
    },
  }
}
</script>
<style scoped>
table td {
  font-size: 13px;
}
.mt15 {
    margin-top: 15px;
}
.mt30 {
    margin-top: 30px;
}

/deep/ .ck-content {
    border: 1px solid #ccc;
    border-top: 0px;
    min-height: 250px
}
</style>