<template>
  <div style="text-align:left;padding: 30px;">
    <div class="card">
    <h3>Delegater</h3>
    <input type="text" v-model="searchQuery" placeholder="Filtrera fram delegat..." class="form-control mb-3">
        <table class="table table-striped table-bordered">
        <thead>
            <tr>
            <th style="width: 50px;text-align: center;">Välj</th>
            <th v-if="!isLoggedIn" style="width: 95%;">Titel</th>
            <th v-if="isLoggedIn" style="width: 50%;">Titel</th>
            <th style="width: 50%;" v-if="isLoggedIn">Namn/Kommentar</th>
            <th style="width: 70px;text-align: center;" v-if="isLoggedIn">Verktyg</th>
            </tr>
        </thead>
          <tbody>
            <tr v-for="(user, index) in filteredUsers" :key="index">
              <td style="text-align:center;"><b-form-checkbox :value="user" v-model="selectedUsers" /></td>
              <td style="text-decoration:underline; font-weight: bold;"><span v-b-modal.modal-5 @click="matchDelegat(user)">{{ user.titel }}</span></td>
              <td v-if="isLoggedIn">{{ user.kommentar }}</td>
              <td v-if="isLoggedIn" class="center-flex">
                <button :id="`popover-1-${index}`" variant="primary" style="float:right;background: none;border: 1px solid #03617d;color: #03617d;border-radius: 5px;"><i class="bi bi-three-dots-vertical"></i></button>
                <b-popover
                    :target="`popover-1-${index}`"
                    :placement="placement"
                    title="Verktyg"
                    triggers="hover focus"
                    custom-class="custom-popover"
                  >
                  <button v-b-modal.modal-8 class="btn btn-primary" style="margin-right: 10px;" @click="editDelegat(user)">Redigera</button>
                  <button @click="deleteUser(user.id)" class="btn btn-danger">Radera</button>
                </b-popover>
              </td>
            </tr>
          </tbody>
        </table>
        <div>
            <b-button @click="selectAllUsers" class="btn btn-info">Markera alla</b-button>
            <b-button @click="deselectAllUsers" class="btn btn-info" style="margin-left: 15px;">Avmarkera alla</b-button>
            <b-button
              @click="showSelectedDelegatTables"
              class="btn btn-primary"
              style="margin-left: 15px;"
              :disabled="selectedUsers.length === 0">
              Visa valda delegaters beslut
            </b-button>
            <b-button v-b-modal.modal-6 class="btn btn-primary" v-if="isLoggedIn" style="float: right;">Lägg till ny delegat</b-button>
        </div>
    </div>
    <b-modal id="modal-5" size="xl" ref="showDelegat-modal" @hidden="onHidden" :title="this.currentDelegat" hide-footer hide-close>
      <p v-if="isLoggedIn">{{ this.currentDelegatKommentar }}</p>
        <div v-if="this.matchDelegations != ''">
            <h6>{{ this.currentDelegat }} är delegat i följande ärenden:</h6>
            <table class="table table-striped table-bordered">
                <thead style="background: #ff8204; color: white;">
                    <tr>
                    <th>Delegationsordning</th>
                    <th>Avsnitt</th>
                    <th>Tabell</th>
                    <th>Nr</th>
                    <th>Typ av ärenden</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="matched in this.matchDelegations" :key="matched.key">
                        <td>{{ matched.delegationsordning }}</td>
                        <td><router-link :to="'/delegationsordningar/5w7OPrQmGlJy1blNmfjd/#' + generateId(matched.avsnitt)">{{ matched.avsnitt }}</router-link></td>
                        <td>{{ matched.tabell }}</td>
                        <td>{{ matched.nr }}</td>
                        <td>{{ matched.arendetyp }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="this.matchVd != ''">
            <h6>{{ this.currentDelegat }} är vidaredelegat i följande ärenden:</h6>
            <table class="table table-striped table-bordered">
                <thead style="background: #ff8204; color: white;">
                    <tr>
                    <th>Delegationsordning</th>
                    <th>Avsnitt</th>
                    <th>Tabell</th>
                    <th>Nr</th>
                    <th>Typ av ärenden</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="matched in this.matchVd" :key="matched.key">
                        <td>{{ matched.delegationsordning }}</td>
                        <td>{{ matched.avsnitt }}</td>
                        <td>{{ matched.tabell }}</td>
                        <td>{{ matched.nr }}</td>
                        <td>{{ matched.arendetyp }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="this.matchVd == '' && this.matchDelegations == ''">
          <h6>{{ this.currentDelegat }} får inte fatta delegationsbesut</h6>
        </div>
    </b-modal>
    <b-modal id="modal-6" ref="addDelegat-modal" title="Lägg till ny delegat" hide-footer hide-close>
        <form>
        <div class="form-group">
            <label for="titel">Titel</label>
            <input type="text" class="form-control" id="titel" v-model="newUser.titel" />
            <label for="titel">Namn/Kommentar</label>
            <input type="text" class="form-control" id="titel" v-model="newUser.kommentar" />
        </div>
        <button type="button" class="btn btn-primary" style="margin-top:20px;" @click="addUser">Lägg till delegat</button>
        </form>
    </b-modal>
    <b-modal id="modal-7" size="xl" title="Valda Delegater" hide-footer @hidden="onHiddenModal7">
    <table class="table table-striped table-bordered">
        <thead style="background: #ff8204; color: white;">
            <tr>
            <th>Delegat</th>
            <th>Delegationsordning</th>
            <th>Avsnitt</th>
            <th>Tabell</th>
            <th>Nr</th>
            <th>Typ av ärenden</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="user in selectedUsers" :key="user.key">
            <td>{{ user.titel }}</td>
            <td>{{ user.delegationsordning }}</td>
            <td>{{ user.avsnitt }}</td>
            <td>{{ user.tabell }}</td>
            <td>{{ user.nr }}</td>
            <td>{{ user.arendetyp }}</td>
            </tr>
        </tbody>
    </table>
  </b-modal>
  <b-modal id="modal-8" size="xl" ref="editDelegat-modal" @hidden="onHidden" title="Redigera delegat" hide-footer>
    <form>
        <div class="form-group">
            <label for="titel">Titel</label>
            <input type="text" class="form-control" id="titel" v-model="editUser.titel" />
            <label for="kommentar">Kommentar</label>
            <input type="text" class="form-control" id="titel" v-model="editUser.kommentar" />
        </div>
        <button type="button" class="btn btn-primary" style="margin-top:20px;" @click="updateUser()">Spara ändringar</button>
    </form>
  </b-modal>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { db } from '../firebase.js';

export default {
  data() {
    return {
    showModal: false,
      newUser: {
        titel: "",
        kommentar: ""
      },
      selectedUsers: [],
      users: [],
      matchDelegations: [],
      matchVd: [],
      editUser: {
        titel: "",
        kommentar: ""
      },
      currentDelegat: "",
      currentDelegatKommentar: "",
      currentUpdateDelegat: "",
      currentId: null,
      deletedUser: null,
      searchQuery: '',
    };
  },
  methods: {
    addUser() {
      // Add new user to Firestore collection "Delegater"
      db.collection("Delegater")
        .add(this.newUser)
        .then(() => {
          console.log("New user added successfully!");
          // Clear input fields
          this.newUser.titel = "";
          this.newUser.kommetar = "";
          // Fetch updated user list from Firestore
          this.fetchUsers();
          this.$refs["addDelegat-modal"].hide();
        })
        .catch((error) => {
          console.error("Error adding new user: ", error);
        });
    },
    fetchUsers() {
      // Fetch all users from Firestore collection "Delegater"
      db.collection("Delegater").orderBy("titel")
        .get()
        .then((querySnapshot) => {
          const users = [];
          querySnapshot.forEach((doc) => {
            // Add the 'id' field to the data fetched from Firestore
            users.push({ id: doc.id, ...doc.data() });
          });
          this.users = users;
          console.log(users);
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    },
    editDelegat(user) {
      this.editUser.titel = user.titel;
      this.currentUpdateDelegat = user.titel;
      if (user.kommentar == null) {
        this.editUser.kommentar = "";
      } else {
        this.editUser.kommentar = user.kommentar;
      }
      this.currentId = user.id;
    },
    updateUser() {
      db.collection("Delegater").doc(this.currentId).update({
        titel: this.editUser.titel,
        kommentar: this.editUser.kommentar
      })
      .then(() => {
        this.updateDelegat(this.currentUpdateDelegat, this.editUser.titel)
        this.editUser.kommentar = "";
        this.editUser.titel = "";
        this.currentId = "";
        this.currentUpdateDelegat = "";
        this.fetchUsers();
        this.$refs["editDelegat-modal"].hide();
      })
      .catch((error) => {
            console.error("Error updating users: ", error);
      });
    },
    updateDelegat(oldTitle, newTitle) {
      const db = firebase.firestore();
      const delegationsRef = db.collection("PendingDelegationsordning");

      delegationsRef.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let docData = doc.data();
          let rows = docData.rows;

          rows.forEach((row, rowIndex) => {
            row.allTables.forEach((allTable, allTableIndex) => {
              allTable.table.forEach((table, tableIndex) => {
                // Check and update vidareDelegat
                if (table.vidareDelegat === oldTitle) {
                  rows[rowIndex].allTables[allTableIndex].table[tableIndex].vidareDelegat = newTitle;
                }

                // Check and update delegat
                if (table.delegat === oldTitle) {
                  rows[rowIndex].allTables[allTableIndex].table[tableIndex].delegat = newTitle;
                }
              });
            });
          });

          // Update the entire 'rows' array for the document
          const docRef = delegationsRef.doc(doc.id);
          docRef.update({ rows: rows })
            .then(() => console.log(`Document updated: ${doc.id}`))
            .catch((error) => console.error("Error updating document: ", error));
        });
      });
    },
    matchDelegat(user) {
      this.currentDelegat = user.titel;
      this.currentDelegatKommentar = user.kommentar;

        // Använd datan från storen
        const delegations = this.$store.state.delegations;

        // Töm eventuella tidigare resultat
        this.matchVd = [];
        this.matchDelegations = [];

        delegations.forEach((delegation) => {
          delegation.rows?.forEach((avsnitt) => {
            avsnitt.allTables?.forEach((allTable) => {
              allTable.table?.forEach((table) => {
                if (table.vidaredelegat?.includes(user.titel)) {
                  const delegationMatch = { 
                    id: delegation.id, 
                    delegationsordning: delegation.title, 
                    avsnitt: avsnitt.avsnitt, 
                    tabell: allTable.id, 
                    nr: table.nr, 
                    arendetyp: table.arendetyp 
                  };
                  this.matchVd.push(delegationMatch);
                }
                if (table.delegat === user.titel) {
                  const delegationMatch = {
                    id: delegation.id, 
                    delegationsordning: delegation.title, 
                    avsnitt: avsnitt.avsnitt, 
                    tabell: allTable.id, 
                    nr: table.nr, 
                    arendetyp: table.arendetyp 
                  };
                  this.matchDelegations.push(delegationMatch);
                    }
                  });
                });
              });
            });
            console.log(this.matchDelegations);
    },
    showSelectedDelegatTables() {
      // Get only the selected users
      const selectedUsers = this.users.filter(user => this.selectedUsers.includes(user));

      // Fetch all delegations for selected users and then show the modal
      const promises = selectedUsers.map(user => this.fetchUserDelegations(user));
      Promise.all(promises).then(delegations => {
        this.selectedUsers = delegations.flat();  // Flatten the array of arrays
        this.$bvModal.show('modal-7');
      });
    },
    fetchUserDelegations(user) {
      const delegatTitel = user.titel;
      const userDelegations = [];  // Create a new array to hold this user's delegations
          
      return new Promise((resolve) => {
        const db = firebase.firestore();
        const delegationsRef = db.collection("PendingDelegationsordning");

        delegationsRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const rows = doc.data().rows;
            rows.forEach((avsnitt) => {
              const allTables = avsnitt.allTables;
              allTables.forEach((allTable) => {
                const tables = allTable.table;
                tables.forEach((table) => {
                  if (table.delegat === delegatTitel || table.vidaredelegat === delegatTitel) {
                    const arendetyp = table.arendetyp;
                    const tabell = allTable.id;
                    const delegation = { 
                      titel: user.titel,
                      delegationsordning: doc.data().title, 
                      avsnitt: avsnitt.avsnitt, 
                      tabell: tabell,
                      nr: table.nr, 
                      arendetyp: arendetyp
                    };
                    userDelegations.push(delegation);  // Add the delegation to the new array
                  }
                });
              });
            });
          });
          resolve(userDelegations);  // Resolve the promise with the user's delegations
        });
      });
    },
    selectAllUsers() {
      this.selectedUsers = [...this.users];
    },

    deselectAllUsers() {
      this.selectedUsers = [];
    },
    onHidden() {
        this.matchDelegations = [];
        this.matchVd = [];
    },
    onHiddenModal7() {
    this.selectedUsers = [];
    },
    deleteUser(id) {
      if (confirm("Vill du verkligen ta bort denna delegat?")) {
        // Delete user from Firestore collection "Delegater"
        db.collection("Delegater")
          .doc(id)
          .delete()
          .then(() => {
            console.log("User deleted successfully!");
            // Fetch updated user list from Firestore
            // Set deletedUser to the id of the deleted user
            this.deletedUser = id;
            this.fetchUsers();
          })
          .catch((error) => {
            console.error("Error deleting user: ", error);
          });
      }
    }
  },
  mounted() {
    // Fetch initial user list on component mount
    if (this.$store.state.delegations.length === 0) {
      // Om inte, hämta datan
      this.$store.dispatch('fetchDelegations');
    }
    this.fetchUsers();
  },
  computed: {
    filteredUsers() {
      return this.users.filter(user => {
        // Adjust this logic based on what fields you want to search
        return user.titel.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    isLoggedIn() {
      return firebase.auth().currentUser !== null; // Check if currentUser is not null
    },
    isAdmin() {
      // Now depends on the data property 'isAdminStatus'
      return this.isAdminStatus;
    },
    generateId() {
      return (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, "-");
      };
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: middle !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

table td,
table th,
table .form-control {
  font-size: 13px;
}

table td a {
  color: black;
}

.popover-header {
    font-size: 15px!important;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>