import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import Axios from 'axios'
import VueMeta from 'vue-meta'
import DelePortal from './components/DelePortal.vue'
import DeleList from './components/DeleList.vue'
import SingleDele from './components/SingleDele.vue'
import OmDele from './components/OmDele.vue'
import DeleHome from './components/DeleHome.vue'
import DeleDiff from './components/DeleDiff.vue'
import DeleReported from './components/DeleReported.vue'
import DeleLogin from './components/DeleLogin.vue';
import DeleRegister from './components/DeleRegister.vue';
import DeleAdmin from './components/DeleAdmin.vue';
import CreateNew from './components/CreateNewDele.vue';
import PublicDele from './components/PublicDele.vue';
import DashboardLayout from './components/DashboardLayout.vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { VueMasonryPlugin } from 'vue-masonry';
import VueEditor from 'vue2-editor';
import firebase from "firebase/compat/app";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import store from './store' // Import the Vuex store
import 'firebase/auth';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'


Vue.prototype.$axios = Axios;
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueMasonryPlugin)
Vue.use(VueEditor);
Vue.use(CKEditor);
Vue.use(VueMeta);

Vue.config.productionTip = false

Vue.use(Router)

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCNQGyNShyUTbCWFPDFfxIdn9Q6b0SQMf8",
  authDomain: "delegationsportalen.firebaseapp.com",
  projectId: "delegationsportalen",
  storageBucket: "delegationsportalen.appspot.com",
  messagingSenderId: "537346964631",
  appId: "1:537346964631:web:1e864405c638f01bcd7dd2",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const router = new Router({
  routes: [
    { path: '/login', name: 'DeleLogin', component: DeleLogin },
    { path: '/register', component: DeleRegister },
    {
      path: '/',  // add a dynamic parameter named "id"
      name: 'DeleHome',
      component: DeleHome,
      props: true,  // enable passing props through the route
      meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
      path: '/delegationsordningar/:id',  // add a dynamic parameter named "id"
      name: 'SingleDele',
      component: SingleDele,
      props: true,  // enable passing props through the route
    },
    {
      path: '/delegationsordning/',
      name: 'DelePortal',
      component: DelePortal,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/',
      name: 'DashboardLayout',
      component: DashboardLayout
    },
    {
      path: '/lagg-till',  // add a dynamic parameter named "id"
      name: 'CreateNew',
      component: CreateNew,
      props: true,  // enable passing props through the route
      meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
      path: '/delegater',
      name: 'DeleList',
      component: DeleList,
    },
    {
      path: '/andringar',
      name: 'DeleDiff',
      component: DeleDiff,
      meta: { requiresAuth: true, requiresAdmin: true }
    },
    {
      path: '/om-delegationsordning',
      name: 'OmDele',
      component: OmDele,
    },
    {
      path: '/publik/:slug',
      name: 'PublicDele',
      component: PublicDele,
    },
    {
      path: '/anmal-andring',
      name: 'DeleReported',
      component: DeleReported,
      meta: { requiresAuth: true }
    },
    {
      path: '/admin',
      name: 'Admin',
      component: DeleAdmin, // Your RegisterUser component
      meta: { requiresAuth: true, requiresAdmin: true }
    },
  ]
})

// Route guard to check if user is authenticated
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const currentUser = firebase.auth().currentUser;
  const isOnLoginPage = to.path === '/login';

  // Function to check admin status
  async function checkAdmin() {
    try {
      const idTokenResult = await currentUser.getIdTokenResult();
      return idTokenResult.claims.isAdmin === true;
    } catch (error) {
      console.error("Error fetching user token:", error);
      return false;
    }
  }

  if (requiresAuth && !currentUser) {
    next('/login');
  } else if (requiresAuth && currentUser && isOnLoginPage) {
    next('/om-delegationsordning');
  } else if (requiresAdmin) {
    if (currentUser) {
      const isAdmin = await checkAdmin();
      if (isAdmin) {
        next();
      } else {
        next('/not-authorized');
      }
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  data() {
    return {
      allowed: true,  // Anta att användaren är tillåten till IP-kontrollen är klar
    };
  },
  beforeCreate() {
    if (!sessionStorage.getItem('allowedIP')) {
      Axios.get('https://us-central1-delegationsportalen-7c62f.cloudfunctions.net/checkIP')
        .then(response => {
          sessionStorage.setItem('allowedIP', response.data.allowed);
          this.allowed = response.data.allowed;
        })
        .catch(error => {
          console.error('Error fetching IP status:', error);
          this.allowed = false;
        });
    } else {
      this.allowed = sessionStorage.getItem('allowedIP') === 'true';
    }
  },
  render(h) {
    // Använd 'allowed' för att bestämma vilken komponent som ska renderas
    return this.allowed ? h(App) : h('h2', 'Ingen åtkomst');
  },
}).$mount('#app');